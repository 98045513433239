import React from 'react';

interface IShared {
  dayValue: Date;
  startDate?: Date;
  endDate?: Date;
  dayReference: Date;
  viewType: 'day' | 'week';
}

type SharedReducerAction =
  | {type: 'UPDATE_START_END_DATES'; payload: Required<Pick<IShared, 'endDate' | 'startDate'>>}
  | {type: 'UPDATE_VIEW_TYPE'; payload: Required<Pick<IShared, 'viewType'>>}
  | {type: 'UPDATE_VIEW_TYPE_DAY_REFERENCE'; payload: Required<Pick<IShared, 'viewType' | 'dayReference'>>}
  | {type: 'UPDATE_DAY_REFERENCE'; payload: Required<Pick<IShared, 'dayReference'>>};

const getDayValue = (shared: Omit<IShared, 'dayValue'>): Date =>
  shared.viewType === 'day' ? shared.dayReference : shared?.startDate || new Date();

const getInitialValue = (): IShared => {
  const initialDayValue = new Date();

  return {
    dayReference: initialDayValue,
    dayValue: getDayValue({dayReference: initialDayValue, viewType: 'week'}),
    viewType: 'week',
  };
};

const SharedContext = React.createContext<IShared>(getInitialValue());
const SharedDispatchContext = React.createContext<React.Dispatch<SharedReducerAction>>(() => undefined);

const sharedReducer = (shared: IShared, action: SharedReducerAction) => {
  switch (action.type) {
    case 'UPDATE_START_END_DATES':
    case 'UPDATE_VIEW_TYPE':
    case 'UPDATE_VIEW_TYPE_DAY_REFERENCE':
    case 'UPDATE_DAY_REFERENCE': {
      const combined: IShared = {
        ...shared,
        ...action.payload,
      };

      return {
        ...combined,
        dayValue: getDayValue(combined),
      };
    }
  }
};

export const SharedProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
  const [shared, dispatch] = React.useReducer(sharedReducer, getInitialValue());

  return (
    <SharedContext.Provider value={shared}>
      <SharedDispatchContext.Provider value={dispatch}>{children}</SharedDispatchContext.Provider>
    </SharedContext.Provider>
  );
};

export const useShared = (): IShared => React.useContext(SharedContext);
export const useSharedDispatch = (): React.Dispatch<SharedReducerAction> => React.useContext(SharedDispatchContext);
