import * as React from 'react';
import {Toasts, UserPermissionsContext} from '@pluto-tv/assemble';
import {BrowserRouter as Router} from 'react-router-dom';

import {useAppDispatch} from 'app/store/hooks';
import AppRouter from 'app/appRouter/AppRouter';
import {permissionsMap, getAppUserPermissions} from 'app/permissions';

import {getRoutesForUserPermissions, TRouteCollection} from 'routes';

import {useMeQuery} from 'features/users/usersApi';
import {setProfile} from 'features/user/userSlice';

import {removeSplashPage} from 'helpers/removeSplashPage';
import {isCommandCenter} from 'helpers/isCommandCenter';

export default (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const {data: userProfile, isFetching, isError} = useMeQuery();

  const userRoutes = React.useMemo(
    (): TRouteCollection => getRoutesForUserPermissions(userProfile?.permissions || []),
    [userProfile],
  );

  const userPermissions = React.useMemo(
    () => getAppUserPermissions(userProfile?.permissions || [], permissionsMap),
    [userProfile],
  );

  const userHasRoles = React.useMemo(() => !!userProfile?.active && !!userProfile?.roles?.length, [userProfile]);

  React.useLayoutEffect(() => {
    if (!isFetching && !isError) {
      removeSplashPage();
    }
  }, [isFetching, isError]);

  React.useEffect(() => {
    if (userProfile) {
      dispatch(setProfile(userProfile));
    }
  }, [dispatch, userProfile]);

  if (!userProfile) {
    return null;
  }

  return (
    <UserPermissionsContext.Provider value={userPermissions}>
      <Router basename={isCommandCenter ? '/cms' : undefined}>
        <AppRouter userRoutes={userRoutes} hasRoles={userHasRoles} />
      </Router>
      <Toasts />
    </UserPermissionsContext.Provider>
  );
};
