import * as React from 'react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {triggerCCEvent} from '@pluto-tv/lib-command-center-ts';
import {Cover, Template, Box, Cluster, Icon, Breadcrumb, Paragraph, Popover, Stack, Sidebar} from '@pluto-tv/assemble';

import NotFound from 'views/notFound/NotFound';
import {IRouteItem, TRouteCollection} from 'routes';

import AppSidebar from 'app/appSidebar/AppSidebar';
import RoleAccessError from 'components/RoleAccessError';

import {useInfoQuery as useBackendInfoQuery} from 'features/backendInfo/backendInfoApi';

import {isCommandCenter} from 'helpers/isCommandCenter';
import {useCurrentRoute} from 'helpers/useCurrentRoute';

import {version, dependencies} from '../../../package.json';

export interface IRouterProps {
  userRoutes: TRouteCollection;
}

export interface IRouterPropsFull extends IRouterProps {
  hasRoles: boolean;
}

export interface IRouterPropsExtra extends IRouterProps {
  currentRoute?: IRouteItem;
  currentRouteExact?: IRouteItem;
}

const assembleVersion = dependencies['@pluto-tv/assemble'];

const AppRouterSwitch = React.memo(({userRoutes}: IRouterProps): JSX.Element => {
  return (
    <Switch>
      {userRoutes.map(route => {
        if (route.children) {
          return route.children.map(r => (
            <Route key={r.name} path={r.path} component={r.component} exact={r.exact || true} />
          ));
        }
        return <Route key={route.name} path={route.path} component={route.component} exact={route.exact || true} />;
      })}
      <Route component={NotFound} />
    </Switch>
  );
});

export const AppRouterFull = ({userRoutes}: IRouterProps): JSX.Element => {
  return (
    <Cover scrolling={true} coverTemplateHeight='100%' maxWidth='100%'>
      <Template label='cover'>
        <AppRouterSwitch userRoutes={userRoutes} />
      </Template>
    </Cover>
  );
};

const AppRouter = ({userRoutes, currentRouteExact, currentRoute}: IRouterPropsExtra): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const {data: backendInfo} = useBackendInfoQuery();

  const breadcrumbNav = React.useCallback(route => history.push(route), [history]);
  const breadcrumbItems = React.useMemo(
    () => (currentRouteExact || currentRoute)?.breadcrumb?.items,
    [currentRoute, currentRouteExact],
  );

  React.useEffect(() => {
    if (isCommandCenter) {
      const decodedUrl = decodeURIComponent(location.search);
      const params = new URLSearchParams(decodedUrl);

      if (params.has('next')) {
        const nextValue = params.get('next');
        if (nextValue) {
          history.push(nextValue);
        }
      }
    }
  }, [history, location.search]);

  React.useEffect(() => {
    if (isCommandCenter) {
      triggerCCEvent('updateHeaderBreadcrumb', {onClick: () => (window.location.href = `/cms/`)});
    }
  }, [location.pathname]);

  return (
    <Cover scrolling={true} coverTemplateHeight='100%' maxWidth='100%'>
      <Template label='header'>
        <Box paddingX='large' paddingY='small' background='black' hideAt='mobile'>
          <Cluster justify='space-between' align='center'>
            {breadcrumbItems && <Breadcrumb onRoute={breadcrumbNav} items={breadcrumbItems}></Breadcrumb>}
            <Popover allowedPlacements={['bottom-end', 'top-end']}>
              <Template label='trigger'>
                <Icon
                  icon='arrowdown'
                  space='xxsmall'
                  iconPosition='right'
                  verticalAlign='text-bottom'
                  size='xsmall'
                  lineHeight='0.8125rem'
                >
                  Versions
                </Icon>
              </Template>
              <Template label='popover'>
                <Box padding='xsmall'>
                  <Stack space='medium'>
                    <Paragraph id='clientVersionNumber'>App Version: {version}</Paragraph>
                    <Paragraph id='apiVersionNumber'>API Version: {backendInfo?.app?.version}</Paragraph>
                    <Paragraph id='assembleVersionNumber'>Assemble Version: {assembleVersion}</Paragraph>
                  </Stack>
                </Box>
              </Template>
            </Popover>
          </Cluster>
        </Box>
      </Template>
      <Template label='cover'>
        <AppRouterSwitch userRoutes={userRoutes} />
      </Template>
    </Cover>
  );
};

export default ({userRoutes, hasRoles}: IRouterPropsFull): JSX.Element => {
  const {currentRoute, currentRouteExact} = useCurrentRoute(userRoutes);

  const isFullscreen = React.useMemo(
    () => currentRouteExact?.fullscreen || currentRoute?.fullscreen,
    [currentRoute, currentRouteExact],
  );

  return isFullscreen ? (
    hasRoles ? (
      <AppRouterFull userRoutes={userRoutes} />
    ) : (
      <RoleAccessError />
    )
  ) : (
    <Sidebar fullHeight={true} backgroundSidebar='cavern' contentMinWidth='25rem' sideWidth='3rem' stackAt='mobile'>
      <>
        <AppSidebar hasRoles={hasRoles} userRoutes={userRoutes} />
        {hasRoles ? (
          <AppRouter userRoutes={userRoutes} currentRoute={currentRoute} currentRouteExact={currentRouteExact} />
        ) : (
          <RoleAccessError />
        )}
      </>
    </Sidebar>
  );
};
