import * as React from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import {forEach} from 'lodash-es';

import {IRouteItem, TRouteCollection} from 'routes';

interface IUseCurrentRoute {
  currentRouteExact?: IRouteItem;
  currentRoute?: IRouteItem;
}

export const useCurrentRoute = (userRoutes: TRouteCollection): IUseCurrentRoute => {
  const location = useLocation();

  const [allRoutes, setAllRoutes] = React.useState<TRouteCollection>([]);

  React.useEffect(() => {
    // Only needs to get called every time the user enters in the Series page
    // or when routes and allRoutes are empty
    if (location.pathname.includes('/series/') || !allRoutes.length) {
      const mapRoutes: TRouteCollection = [];

      forEach(userRoutes, route => {
        if (route.children) {
          forEach(route.children, child => mapRoutes.push(child));
          return;
        }

        mapRoutes.push(route);
      });

      setAllRoutes(mapRoutes);
    }
  }, [allRoutes.length, userRoutes, location.pathname]);

  const currentRouteExact = React.useMemo(
    () => allRoutes.find(r => r.path === location.pathname),
    [allRoutes, location.pathname],
  );
  const currentRoute = React.useMemo(
    () => allRoutes.filter(r => (location.pathname ? matchPath(location.pathname, r) : false)).pop(),
    [allRoutes, location.pathname],
  );

  return {
    currentRouteExact,
    currentRoute,
  };
};
