import {orderBy} from 'lodash-es';
import {IChannelCatalogItemWithState} from 'views/programming/channel/contexts/memoryQueueApiProvider';

const getSplittedSort = (sort: string) => {
  const splitedSort = sort.split(':');
  return splitedSort;
};

export const sortQueue = (queue: IChannelCatalogItemWithState[], sort: string): IChannelCatalogItemWithState[] => {
  const tempQueue = [...queue];

  // eslint-disable-next-line prefer-const
  const [sortKey, sortOrder] = getSplittedSort(sort);

  let allSortOrder: 'asc' | 'desc' = 'asc';

  // For lodash's benefit
  if (sortOrder === 'dsc') {
    allSortOrder = 'desc';
  }

  let sortKeys: (string | ((a: IChannelCatalogItemWithState) => string))[] = [];
  let sortOrders: ('asc' | 'desc')[] = [];

  if (sortKey === 'author') {
    sortKeys = ['author', a => a.series.name, 'season', 'number'];
    sortOrders = [allSortOrder, allSortOrder, allSortOrder, allSortOrder];
  } else {
    sortKeys = [sortKey];
    sortOrders = [allSortOrder];
  }

  return orderBy(tempQueue, sortKeys, sortOrders);
};
