import * as React from 'react';
import {useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Cluster,
  Cover,
  DateTime,
  Divider,
  Drawer,
  Expand,
  FormItem,
  Grid,
  Heading,
  Help,
  Icon,
  ISimpleScheduleEntry,
  Label,
  Paragraph,
  Popover,
  Range,
  Select,
  Stack,
  Table,
  TdMultilineTruncate,
  TZoomLevelsScheduler,
  Template,
  TextInput,
  Timeline,
  Toast,
  summon,
  secondsToHms,
  IScheduleEntry,
  ISelectedDrop,
  useUndo,
  trigger,
  timelineDragHandler,
} from '@pluto-tv/assemble';
import {debounce, get} from 'lodash-es';
import {DateTime as luxon} from 'luxon';

import {hasOverlap} from 'views/programming/channel/utils/recurrence';
import {
  ITimeline,
  useFindDraftTimelinesQuery,
  useFindTimelinesQuery,
} from 'features/channelTimelines/channelTimelinesApi';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import {IChannelCatalogItem} from 'models/channelCatalog';
import EpisodesDetails, {
  isSimpleScheduleEntry,
} from 'views/programming/channel/edit/program/components/EpisodesDetails';
import {
  findMaxManualOverride,
  formatDateWithTimezone,
  formatScreeningTime,
  startEndOfWeek,
  disablePastDates,
} from 'utils/dateUtils';
import {INestedChannelProgramProps} from '../nestedPropsInterface';
import {
  ITimeframe,
  getDraftTimeline,
  getMinStart,
  getTimelineState,
  mapTimelinesIntoTimelinesEntry,
  mapToSimpleScheduleEntries,
  addEpisodes,
  getStartTimeToDrop,
  sanitizeDraftEventList,
  addDays,
} from './utilities';
import {useKeyboardShortcut} from 'views/programming/channel/edit/program/hooks/useKeyboardShortcut';
import {recalculateEventTimes} from './utilities';
import {useMultiSelect} from 'views/programming/channel/edit/program/hooks/useMultiSelect';
import {useChannelCatalogQueue} from 'views/programming/channel/edit/catalog/hooks/useChannelCatalogQueue';
import {
  IChannelCatalogItemWithState,
  useMemoryQueueApiProvider,
} from 'views/programming/channel/contexts/memoryQueueApiProvider';
import {LoadingBox} from 'components/loadingBox';
import {useAppPermissions} from 'app/permissions';
import EpisodeRecurrence from 'views/programming/channel/edit/program/components/EpisodeRecurrence';
import useRecurrenceValidation from 'views/programming/channel/edit/program/hooks/useRecurrenceValidation';
import {RowState} from 'components/rowState';

import {useShared, useSharedDispatch} from '../sharedContext';
import {StateColorType} from '../../utils';

import './index.css';

export type SelectMode = 'vertical-select' | 'horizontal-select' | 'group-select';

export interface IProgramRef {
  cleanPeriod: () => void;
}

const TABLE_COLUMN_NAMES = {
  Episode: 'name',
  Author: 'author',
  'Rat.': 'rating',
  Series: 'series.name',
  S: 'season',
  E: 'number',
  'Dur.': 'duration',
} as const;

const TableColumnLabel = {
  name: 'Episode',
  rating: 'Rat.',
  'series.name': 'Series',
  season: 'S',
  number: 'E',
  duration: 'Dur.',
  author: 'Author',
};

const EpisodeQueueSearchModal = React.lazy(
  () => import('views/programming/channel/edit/program/components/EpisodeQueueSearchModal'),
);

const LeaveCalendarDialog = React.lazy(
  () => import('views/programming/channel/edit/program/components/LeaveCalendarDialog'),
);

const mapCatalogToEntry = (row: IChannelCatalogItemWithState | ISimpleScheduleEntry): IScheduleEntry => ({
  duration: row.duration,
  fullHeight: '3rem',
  height: 'auto',
  id: row.id,
  offsetTop: '0px',
  rating: row.rating,
  title: row.series.name,
  subTitle: row.name,
  start: new Date(),
  state: row.state?.toString() as StateColorType,
});

const ProgramPage = React.memo(
  React.forwardRef<IProgramRef, INestedChannelProgramProps>(
    (
      {
        model,
        programModel,
        onGapsChange,
        programHasChanges,
        setProgramFields,
        setProgramModel,
        onClickSaveHandler,
        isDraft,
        setIsDraft,
        moveToPeriod,
        isOpenLeaveDialog,
        setIsOpenLeaveDialog,
        lastProgrammingUpdate,
        attemptToLeave,
        cancelAttemptToLeave,
        setGapChecksPending,
        isChannelDraftDiscarded,
        isDiscardTimelineDraftsLoading,
      }: INestedChannelProgramProps,
      ref,
    ) => {
      const {id: channelId} = useParams<{id: string}>();

      // Ref to track mounted state
      const isMountedRef = React.useRef(false);

      // Update the ref on mount/unmount
      React.useEffect(() => {
        isMountedRef.current = true;

        return () => {
          isMountedRef.current = false;
        };
      }, []);

      const {dayReference, viewType, dayValue, endDate, startDate} = useShared();

      const dispatch = useSharedDispatch();

      const [isQueueExpanded, setIsQueueExpanded] = React.useState(true);
      const [isRightExpanded, setIsRightExpanded] = React.useState(false);
      const [isSnapOn, setIsSnapOn] = React.useState(false);

      const [isAddEpisodeOpen, setIsAddEpisodeOpen] = React.useState(false);
      const [isRecurrenceOpen, setIsRecurrenceOpen] = React.useState(false);
      const [timeToAddNewEntry, setTimeToAddNewEntry] = React.useState<number | undefined>();

      const [episodeInfo, setEpisodeInfo] = React.useState<'details' | 'recurrence'>('details');

      const clipboardRef = React.useRef<(ISimpleScheduleEntry | IChannelCatalogItem)[]>([]);
      const clipboardTimelinesRef = React.useRef<ITimeline[]>([]);

      const [composedRecurrenceMessage, setComposedRecurrenceMessage] = React.useState<boolean>(false);

      const [lastRepeatSchedule, setLastRepeatSchedule] = React.useState<number>();

      const [copyMode, setCopyMode] = React.useState<SelectMode>('horizontal-select');

      const {set: setUndo, undo, redo, canUndo, canRedo, reset: resetUndo} = useUndo<ITimeline[]>();

      const {
        isValidRecurrence,
        recurrenceWarning,
        repeatPeriod,
        endRepeatType,
        endRepeatDate,
        timeframe,
        cycleOver,
        occurrences,
        sunRepeat,
        monRepeat,
        tueRepeat,
        wedRepeat,
        thuRepeat,
        friRepeat,
        satRepeat,
        positioning,
        setPositioning,
        setRepeatPeriod,
        setEndRepeatType,
        setEndRepeatDate,
        setTimeframe,
        setCycleOver,
        setOccurrences,
        setSunRepeat,
        setMonRepeat,
        setTueRepeat,
        setWedRepeat,
        setThuRepeat,
        setFriRepeat,
        setSatRepeat,
        handleAddRepeatSchedule: handleRepeatSchedule,
      } = useRecurrenceValidation(channelId);

      const timelineRef = React.useRef<any>();

      const {ableTo, permissions} = useAppPermissions();
      // end recurrence options

      // Entries to display in the calendar view
      const [publishedTimelinesEntries, setPublishedTimelinesEntries] = React.useState<ISimpleScheduleEntry[]>([]);
      const [draftTimelinesEntries, setDraftTimelinesEntries] = React.useState<ISimpleScheduleEntry[] | undefined>();

      const draftTimelines = React.useMemo(() => programModel.draft, [programModel.draft]);

      // Used to clean the entries arrays to not display the green pulse when the calendar
      // is switched from draft to publish or viceversa.
      const [isReady, setIsReady] = React.useState(false);

      React.useEffect(() => {
        setPublishedTimelinesEntries([]);
        setDraftTimelinesEntries([]);
        setIsReady(true);
      }, [isDraft]);

      React.useEffect(() => {
        if (!isReady || !draftTimelines || !isDraft) return;

        setDraftTimelinesEntries(
          mapTimelinesIntoTimelinesEntry(
            draftTimelines || [],
            !!(model.tmsid && model.gracenoteIntegration?.dynamicClip),
            isDraft && ableTo('CHANNEL_EPG_EDIT'),
          ),
        );
      }, [model.gracenoteIntegration?.dynamicClip, model.tmsid, draftTimelines, isDraft, ableTo, isReady]);

      const setDraftTimelinesToModel = React.useCallback(
        (timelines: ITimeline[]): void => {
          setProgramFields({draft: timelines});
        },
        [setProgramFields],
      );

      React.useEffect(() => {
        setGapChecksPending(true);
        setTimeout(() => setShouldCheckForGaps(true), 2500);
      }, [draftTimelinesEntries, setGapChecksPending]);

      const [autofillDaysOptions, setAutofillDaysOptions] = React.useState<{label: string; value: number}[]>([]);
      const [daysToAutofillSelected, setDaysToAutofillSelected] = React.useState<number>(1);

      const [isAddingQueueAutofill, setIsAddingQueueAutofill] = React.useState(false);

      const [selectedEpisodes, setSelectedEpisodes] = React.useState<ISimpleScheduleEntry[]>([]);
      const [selectedQueue, setSelectedQueue] = React.useState<IChannelCatalogItem[]>([]);

      // Flag used to show entries in the right panel when click on them
      const [shouldOpenRightInfoPanel, setShouldOpenRightInfoPanel] = React.useState(false);

      const [isEditingRecurrenceField, setIsEditingRecurrenceField] = React.useState(false);
      const [recurrenceResponseFromModal, setRecurrenceResponse] = React.useState();

      const [lockedEvent, setLockedEvent] = React.useState<ITimeline | null>(null);

      const [zoomLevel, setZoomLevel] = React.useState<TZoomLevelsScheduler>(1);
      const [increment, setIncrement] = React.useState<'small' | 'medium'>('medium');

      const timestampRef = React.useRef<number | null>();

      React.useImperativeHandle(ref, () => ({
        cleanPeriod: () => {
          timestampRef.current = undefined;
          setShouldOpenRightInfoPanel(false);
          setSelectedEpisodes([]);
          setSelectedQueue([]);
          resetUndo();
        },
      }));

      const handleCopyMode = (mode: SelectMode) => {
        if (mode === copyMode) return;
        setCopyMode(mode);
        trigger('changeTimelineDragHorizontal', mode === 'horizontal-select');
      };

      const handleCopy = () => {
        if (!ableTo('CHANNEL_EPG_EDIT') || (!selectedEpisodes.length && !selectedQueue.length)) return;
        const isTimelineList = selectedEpisodes.length > 0;

        // keep track of copied timelines entries, they are needed when pasting in other weeks
        if (isTimelineList) {
          clipboardTimelinesRef.current = selectedEpisodes.reduce((acc, episode) => {
            const timeline = draftTimelines?.find(timeline => timeline.id === episode.id);
            if (!timeline) return acc;
            return [...acc, timeline];
          }, [] as ITimeline[]);
        }

        clipboardRef.current = isTimelineList ? selectedEpisodes : selectedQueue;

        Toast.success(`${clipboardRef.current.length} items copied to clipboard`);
        // Trigger event so the Timeline component can handle it and show the highlight and the paste icon
        trigger(
          'timeline-copy',
          !isTimelineList
            ? mapToSimpleScheduleEntries(clipboardRef.current)
            : (clipboardRef.current as ISimpleScheduleEntry[]),
        );
      };

      const handleUndo = () => {
        if (!ableTo('CHANNEL_EPG_EDIT') || !canUndo()) return;

        const previousTimeline = undo();

        if (previousTimeline) {
          setDraftTimelinesToModel(previousTimeline);
        }
      };

      const handleRedo = () => {
        if (!ableTo('CHANNEL_EPG_EDIT') || !canRedo()) return;

        const timeline = redo();

        if (timeline) {
          setDraftTimelinesToModel(timeline);
        }
      };

      const handleDelete = () => {
        const episodesToRemove = selectedEpisodes.filter(episode => !episode.locked);
        if (
          !ableTo('CHANNEL_EPG_EDIT') ||
          !episodesToRemove.length ||
          (isRightExpanded && episodeInfo === 'recurrence' && isEditingRecurrenceField)
        )
          return;

        const newDraftTimeline = (draftTimelines || []).filter(timeline => {
          return !episodesToRemove.some(episode => episode.id === timeline.id);
        });

        const allDraftTimelinesSanitized = sanitizeDraftEventList(
          newDraftTimeline,
          getCurrentStartDate().start,
          isSnapOn,
        );

        setDraftTimelinesToModel(allDraftTimelinesSanitized);
        setSelectedEpisodes(prevEpisodes => prevEpisodes.filter(episode => episode.locked));
        setUndo(allDraftTimelinesSanitized);
      };

      const handleRemove = (id: string) => {
        const newArray = (draftTimelines || []).filter(item => item.id !== id);

        const {start: currStartDate} = getCurrentStartDate();
        const allDraftTimelinesSanitized = sanitizeDraftEventList(newArray, currStartDate, isSnapOn);

        setDraftTimelinesToModel(allDraftTimelinesSanitized);

        setSelectedEpisodes(prevItems => prevItems.filter(item => item.id !== id));
        setUndo(allDraftTimelinesSanitized);
      };

      const debouncedZoom = React.useMemo(() => debounce(val => setZoomLevel(val), 500), []);

      // These are used to enable the Autofill button
      const [existGapsInTimeline, setExistGapsInTimeline] = React.useState(false);
      const [openMsgWhenAutofillDisabled, setOpenMsgWhenAutofillDisabled] = React.useState(false);

      const [shouldCheckForGaps, setShouldCheckForGaps] = React.useState(true);

      useToggleSearchBarOnSlash(
        setIsQueueExpanded,
        isQueueExpanded,
        model?.gracenoteIntegration?.enabled || isAddEpisodeOpen,
      );

      const {
        currentData: draftTimelinesApiResponse,
        isLoading: isDraftTimelinesLoading,
        isFetching: isDraftTimelinesFetching,
        isSuccess: isDraftTimelinesSuccess,
        isError: isDraftTimelinesError,
      } = useFindDraftTimelinesQuery(
        {
          channelId,
          startDate: startDate?.getTime() || 0,
          endDate: endDate?.getTime() || 0,
          lastUpdate: lastProgrammingUpdate.valueOf(),
          draftDiscarded: isChannelDraftDiscarded,
        },
        {skip: !startDate && !endDate, refetchOnMountOrArgChange: true},
      );

      const {
        currentData: timelines,
        isLoading: isTimelinesLoading,
        isFetching: isTimelinesFetching,
        isSuccess: isTimelinesSuccess,
        isError: isTimelinesError,
      } = useFindTimelinesQuery(
        {
          channelId,
          startDate: startDate?.getTime() || 0,
          endDate: endDate?.getTime() || 0,
          lastUpdate: lastProgrammingUpdate.valueOf(),
          draftDiscarded: isChannelDraftDiscarded,
        },
        {skip: !startDate && !endDate, refetchOnMountOrArgChange: true},
      );

      const {createNewTimelinesAutoFill, findEpisodeInQueue} = useMemoryQueueApiProvider();

      const {
        queueItems: queueItemsRetrieved,
        isLazyLoading: isQueueLazyLoading,
        isLoading: isQueueLoading,
        isError: isQueueError,
        totalItems: queueTotalItems,
        handleLazyLoad: handleQueueLazyLoad,
        duration: queueDuration,
        handleSorting: handleQueueSorting,
        sort: queueSort,
      } = useChannelCatalogQueue(false, model.id);

      const [handleClickQueueEntries] = useMultiSelect<IChannelCatalogItem>(
        queueItemsRetrieved,
        selectedQueue,
        setSelectedQueue,
      );

      const onClickQueueTableRow = React.useCallback(
        (row: IChannelCatalogItemWithState, index: number) => {
          if (isRightExpanded) {
            if (selectedQueue.length === 1 && row.id === selectedQueue[0].id) {
              setIsRightExpanded(false);
              setShouldOpenRightInfoPanel(false);
            }
          } else {
            setShouldOpenRightInfoPanel(true);
          }

          if (selectedEpisodes.length > 0) {
            setSelectedEpisodes([]);
          }

          handleClickQueueEntries([index]);
        },
        [handleClickQueueEntries, isRightExpanded, selectedEpisodes.length, selectedQueue],
      );

      const handleSorting = React.useCallback(
        (sortField: string) => {
          handleQueueSorting(sortField);
        },
        [handleQueueSorting],
      );

      const queueOnSort = React.useCallback(
        (columnLabel: string) => handleSorting(TABLE_COLUMN_NAMES[columnLabel]),
        [handleSorting],
      );

      const [handleClickItems] = useMultiSelect<ISimpleScheduleEntry>(
        isDraft ? draftTimelinesEntries || [] : publishedTimelinesEntries,
        selectedEpisodes,
        setSelectedEpisodes,
      );

      React.useEffect(() => {
        trigger(
          'selectedTimelineEntries',
          selectedEpisodes.map(e => mapCatalogToEntry(e)),
        );
      }, [selectedEpisodes]);

      const onCustomRowDrag = React.useCallback((ev, row) => {
        timelineDragHandler.startDrag(ev, mapCatalogToEntry(row));
      }, []);

      const canModifyEdit = React.useMemo(
        (): boolean =>
          !model?.gracenoteIntegration?.enabled &&
          !model?.cfaasIntegration?.enabled &&
          isDraft &&
          ableTo('CHANNEL_EPG_EDIT'),
        [isDraft, model?.gracenoteIntegration?.enabled, model?.cfaasIntegration?.enabled, ableTo],
      );

      const handleCloseRightPanel = React.useCallback(() => {
        setIsRightExpanded(false);
        setShouldOpenRightInfoPanel(false);
        setSelectedEpisodes([]);
        setSelectedQueue([]);
        setEpisodeInfo('details');
        setIsRecurrenceOpen(false);
      }, []);

      // This will close right panel and clean all selected list items
      useKeyboardShortcut('escape', handleCloseRightPanel);

      // Open recurrence from shortcut
      useKeyboardShortcut('ctrl+i, command+i', () => setIsRecurrenceOpen(!!(selectedEpisodes.length === 1)));

      React.useEffect(() => {
        if (selectedEpisodes.length || selectedQueue.length) {
          setEpisodeInfo('details');
        }
      }, [selectedEpisodes, selectedQueue]);

      React.useEffect(() => {
        handleCloseRightPanel();
      }, [handleCloseRightPanel, isDraft]);

      const isScheduleLoading = React.useMemo(() => {
        if (isDraft) {
          return isDraftTimelinesLoading || isDraftTimelinesFetching;
        }

        return isTimelinesLoading || isTimelinesFetching;
      }, [isDraft, isTimelinesLoading, isDraftTimelinesLoading, isDraftTimelinesFetching, isTimelinesFetching]);

      const transformEntry = React.useCallback((item: IScheduleEntry) => <EpisodeTemplate item={item} />, []);
      const transformTopEntry = React.useCallback((item: IScheduleEntry) => <EpisodeTopTemplate item={item} />, []);

      const getTimelinesConsideringError = (isSuccess: boolean, timelines?: ITimeline[]): ITimeline[] => {
        if (isSuccess) {
          return timelines || [];
        }

        return [];
      };

      React.useEffect(() => {
        if (isDraftTimelinesLoading || isTimelinesLoading || isDraftTimelinesFetching || isTimelinesFetching) return;

        if ((isDraftTimelinesSuccess || isDraftTimelinesError) && (isTimelinesSuccess || isTimelinesError)) {
          const {events, borderEvent} = getDraftTimeline(
            getTimelinesConsideringError(isDraftTimelinesSuccess, draftTimelinesApiResponse),
            getTimelinesConsideringError(isTimelinesSuccess, timelines),
          );

          const draftEvents = events.map(item => {
            const start = new Date(item.start);
            const {state, stateMsgList} = getTimelineState({...item, ...item.episode});
            return {
              ...item,
              start,
              stop: new Date(item.stop),
              locked: start < getMinStart() || model.gracenoteIntegration?.enabled || model.cfaasIntegration?.enabled,
              state,
              stateMsgList,
            };
          });

          setProgramModel({
            draft: draftEvents,
            published: timelines?.map(t => ({...t, stop: new Date(t.stop)})),
            startDate: startDate?.getTime() || 0,
            endDate: endDate?.getTime() || 0,
          });

          if (borderEvent) {
            setLockedEvent(borderEvent);
          }
          setUndo(draftEvents, {locked: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        isDraftTimelinesLoading,
        isTimelinesLoading,
        draftTimelinesApiResponse,
        timelines,
        endDate,
        isDraftTimelinesSuccess,
        isTimelinesSuccess,
        isDraftTimelinesError,
        isTimelinesError,
        model.gracenoteIntegration?.dynamicClip,
        model.gracenoteIntegration?.enabled,
        model.tmsid,
        setProgramModel,
        startDate,
      ]);

      React.useEffect(() => {
        let {startDate: newStartDate, endDate: newEndDate} = startEndOfWeek(dayReference);

        newStartDate = new Date(newStartDate.setHours(0, 0, 0, 0));
        newEndDate = new Date(newEndDate.setHours(23, 59, 59, 999));

        // when switching from viewType day to week there's no need to set again the start and end dates.
        if (startDate?.getTime() === newStartDate.getTime() && endDate!.getTime() === newEndDate.getTime()) return;

        dispatch({
          type: 'UPDATE_START_END_DATES',
          payload: {
            endDate: newEndDate,
            startDate: newStartDate,
          },
        });

        const todayDate = new Date();
        let totalDays: number;
        let selectedDayToStart = 1;

        if (newStartDate > todayDate) {
          // Future week
          totalDays = 7;
        } else if (newEndDate < todayDate) {
          // Previous week
          totalDays = 0;
          selectedDayToStart = 0;
        } else {
          // current week
          totalDays = 7 - todayDate.getDay() + 1;
        }

        const options: {label: string; value: number}[] = [];

        for (let index = 0; index < totalDays; index++) {
          options.push({label: (index + 1).toString(), value: index + 1});
        }

        setAutofillDaysOptions(options);
        setDaysToAutofillSelected(selectedDayToStart);
        cancelAttemptToLeave();

        isDraft ? setDraftTimelinesEntries([]) : setPublishedTimelinesEntries([]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dayReference, dispatch]);

      const [isPageLoading, setIsPageLoading] = React.useState(true);

      React.useEffect(() => {
        setTimeout(() => {
          if (isMountedRef.current) {
            setIsPageLoading(!startDate || isDraftTimelinesLoading || isTimelinesLoading || isQueueLoading);
          }
        }, 500);
      }, [isDraftTimelinesLoading, isTimelinesLoading, isQueueLoading, startDate]);

      React.useEffect(() => {
        if (isDraft || !isReady) return;

        if (!isTimelinesLoading && timelines?.length) {
          const publishedTimelines: ITimeline[] = timelines.map(timeline => {
            const {state, stateMsgList} = getTimelineState({...timeline, ...timeline.episode});

            return {
              ...timeline,
              start: new Date(timeline.start),
              stop: new Date(timeline.stop),
              locked:
                new Date(timeline.start) < getMinStart() ||
                model.gracenoteIntegration?.enabled ||
                model.cfaasIntegration?.enabled,
              state,
              stateMsgList,
            };
          });
          setPublishedTimelinesEntries(
            mapTimelinesIntoTimelinesEntry(
              publishedTimelines,
              !!(model.tmsid && model.gracenoteIntegration?.dynamicClip),
              false,
            ),
          );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        isTimelinesLoading,
        model.tmsid,
        timelines,
        isDraft,
        isReady,
        model.gracenoteIntegration?.enabled,
        model.cfaasIntegration?.enabled,
      ]);

      React.useEffect(() => {
        if (isQueueError) {
          Toast.error('There was an error fetching the Episodes queue, please try again.');
          setIsAddingQueueAutofill(false);
        }
      }, [isQueueError]);

      React.useEffect(() => {
        const checkForGaps = () => {
          if (isDraft && shouldCheckForGaps && endDate) {
            if (!draftTimelines?.length) {
              setGapChecksPending(false);
              setExistGapsInTimeline(true);
              onGapsChange([]);
              return;
            }

            let existGaps = false;
            let gaps = timelineRef?.current?.getGaps();

            onGapsChange(gaps);

            if (gaps?.length > 0) {
              const now = new Date();
              gaps = gaps.filter(gap => gap.end <= endDate.getTime() && gap.end > now.getTime());
              existGaps = gaps?.length > 0;
            }

            if (!existGaps && endDate && draftTimelines?.length) {
              // the last event ends before the endDate of the calendar
              existGaps = draftTimelines[draftTimelines.length - 1].stop <= endDate;
            }
            setExistGapsInTimeline(existGaps);
            setShouldCheckForGaps(false);
            setGapChecksPending(false);
          }
        };

        checkForGaps();
      }, [draftTimelines, endDate, isDraft, onGapsChange, shouldCheckForGaps, setGapChecksPending]);

      const switchView = React.useCallback(
        (viewType: 'day' | 'week'): void => {
          if (viewType === 'day') {
            const today = new Date();
            const dayRefereceToSet = startDate && endDate && startDate <= today && endDate >= today ? today : startDate;

            dispatch({
              payload: {
                viewType,
                dayReference: dayRefereceToSet!,
              },
              type: 'UPDATE_VIEW_TYPE_DAY_REFERENCE',
            });
          } else {
            dispatch({
              payload: {
                viewType,
              },
              type: 'UPDATE_VIEW_TYPE',
            });
          }
        },
        [endDate, startDate, dispatch],
      );

      // Start date where events can be added or deleted
      const getCurrentStartDate = React.useCallback(() => {
        const now = new Date();

        const lockedEventStop = lockedEvent ? new Date(lockedEvent.stop) : null;
        const today = lockedEventStop && lockedEventStop > now ? lockedEventStop : now;
        let start = startDate;

        if (!start || start < today) {
          start = today;
        }

        return {start, today};
      }, [lockedEvent, startDate]);

      //#region AUTOFILL

      const getLastTimelineEntry = (start: Date, stop: Date): ITimeline | undefined => {
        if (draftTimelines?.length) {
          const lastTimeline = draftTimelines
            .filter(
              draft => (draft.start >= start && draft.start <= stop) || (draft.stop >= start && draft.stop <= stop),
            )
            ?.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime())[0];

          return lastTimeline;
        }
      };

      const getTimeframes = (start: Date, stop: Date) => {
        // Gaps to fill in

        // if there's no timelines, create only 1 timeframe from our start and stop original values
        let timeframes = [{start, stop}];

        /**
         * This finds the starting point in which to begin autofilling content.
         * This start point is the end of the last programmed episode in the programming range.
         */
        const filteredDraftTimelines = draftTimelines?.filter(
          draft => (draft.stop > start && draft.stop <= stop) || (draft.start >= start && draft.start < stop),
        );

        if (filteredDraftTimelines?.length) {
          let currStart = start;

          timeframes = [];

          filteredDraftTimelines
            .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
            ?.forEach(timeline => {
              // if timeline.start is greater than our original start point it means
              // that we have a gap, therefore we add this timeframe in our list
              // and set the start point to be the end of that timeline
              const timelineStart = new Date(timeline.start);
              if (timelineStart.getTime() > currStart.getTime()) {
                timeframes.push({start: currStart, stop: timelineStart});
              }

              currStart = new Date(timeline.stop);
            });

          // check if there's a gap between our last timeline and our stop
          if (stop && stop.getTime() > currStart.getTime()) {
            timeframes.push({start: currStart.getTime() > start.getTime() ? currStart : start, stop: stop});
          }
        }

        return timeframes;
      };

      const createTimelines = async (timeframes: ITimeframe[], lastDraftTimeline: ITimeline | undefined) => {
        const newTimelines = await createNewTimelinesAutoFill(timeframes, lastDraftTimeline);
        const allDraftTimelines = [...(draftTimelines || []), ...(newTimelines || [])];

        const {start} = getCurrentStartDate();
        const allDraftTimelinesSanitized = sanitizeDraftEventList(allDraftTimelines, start || new Date(), isSnapOn);

        setDraftTimelinesToModel(allDraftTimelinesSanitized);
        setUndo(allDraftTimelinesSanitized);
      };

      const autofill = async () => {
        setIsAddingQueueAutofill(true);
        handleCloseRightPanel();

        const daysInMiliSec = (daysToAutofillSelected || 7) * 24 * 60 * 60 * 1000;
        // eslint-disable-next-line prefer-const
        let {start, today} = getCurrentStartDate();
        if (model.tmsid && model.gracenoteIntegration?.dynamicClip && draftTimelines) {
          const {maxManualOverride, todayPlus30Min} = findMaxManualOverride(draftTimelines);

          if (maxManualOverride) {
            start = maxManualOverride;
          } else if (start <= today) {
            start = todayPlus30Min;
          }
        }
        const stopInMiliSec = start.getTime() + daysInMiliSec;

        let stop = new Date(stopInMiliSec);

        if (endDate && stop > endDate) {
          stop = endDate;
        }

        const timeframes = getTimeframes(start, stop);
        const lastTimeline = getLastTimelineEntry(start, stop);

        await createTimelines(timeframes, lastTimeline);
        // Reset state
        setIsAddingQueueAutofill(false);
      };
      //#endregion AUTOFILL

      const addSeriesToCalendar = (items: IChannelCatalogItemWithState[], doToast = true): boolean => {
        if (!!timeToAddNewEntry && items.length) {
          let nextStartEpisode = timeToAddNewEntry;
          let episodeIndex = (draftTimelines || []).length;

          const newDraftTimelines = items.map(item => {
            const timeline: ITimeline = {
              id: `${item.id}-${episodeIndex}`,
              start: new Date(new Date(nextStartEpisode).setMilliseconds(0)),
              stop: new Date(nextStartEpisode + item.allotment * 1000),
              episode: item,
              state: item.state,
              stateMsgList: item.stateMsgList,
              isRecurrenceEvent: false,
            };

            nextStartEpisode = nextStartEpisode + item.allotment * 1000;

            episodeIndex = episodeIndex + 1;

            return timeline;
          });

          addEpisodesToCalendar(newDraftTimelines, doToast);
          return true;
        }
        return false;
      };

      const addEpisodesToCalendar = React.useCallback(
        (newDraftTimelines: ITimeline[], doToast = true) => {
          if (!newDraftTimelines.length) return;

          const currentWeekDraftTimelies = draftTimelines?.filter(draft => startDate && draft.stop >= startDate);

          const allDraftTimelines = addEpisodes(newDraftTimelines || [], currentWeekDraftTimelies || []);

          const allDraftTimelinesSanitized = sanitizeDraftEventList(
            allDraftTimelines,
            getCurrentStartDate().start || new Date(),
            isSnapOn,
          );

          setDraftTimelinesToModel(allDraftTimelinesSanitized);
          setUndo(allDraftTimelinesSanitized);
          if (doToast) {
            Toast.success(`${newDraftTimelines.length} Episodes were added to the Calendar`);
          }
        },
        [draftTimelines, getCurrentStartDate, isSnapOn, setDraftTimelinesToModel, setUndo, startDate],
      );

      const addTimelinesToCalendar = React.useCallback(
        (items: (ISimpleScheduleEntry | IChannelCatalogItem)[], timeToAddNewEntry?: number) => {
          const listHasTimelines = items.some(isSimpleScheduleEntry);

          const startDateToDrop = listHasTimelines
            ? getStartTimeToDrop(items as ISimpleScheduleEntry[], timeToAddNewEntry)
            : timeToAddNewEntry;

          if (
            (!listHasTimelines && !timeToAddNewEntry) ||
            (!startDateToDrop && !timestampRef.current) ||
            (startDateToDrop &&
              startDate &&
              endDate &&
              (new Date(startDateToDrop) < startDate || new Date(startDateToDrop) > endDate))
          ) {
            Toast.error(`No area defined to paste the ${items.length === 1 ? 'entry' : 'entries'} on the calendar.`);
            return;
          }

          if (startDateToDrop) timestampRef.current = startDateToDrop;

          addEpisodesToCalendar(
            recalculateEventTimes(
              items,
              clipboardTimelinesRef.current || [],
              new Date((startDateToDrop || timestampRef.current)!),
              !timeToAddNewEntry ? 'vertical-select' : copyMode,
            ),
          );

          setSelectedEpisodes([]);
          setSelectedQueue([]);
          setShouldOpenRightInfoPanel(false);
        },
        [addEpisodesToCalendar, copyMode, endDate, startDate],
      );

      const handleAddRepeatSchedule = async (episodeToRepeat: IChannelCatalogItem | ISimpleScheduleEntry) => {
        const lastRepeatRequest = new Date().getTime();
        setLastRepeatSchedule(lastRepeatRequest);

        const response = await handleRepeatSchedule(episodeToRepeat, lastRepeatRequest);
        setRecurrenceResponse(response);
      };

      const findOverlappingRecurrentEvents = React.useCallback((allEvents: ITimeline[], recurrentEvents: any[]) => {
        const overlappingRecurrentEvents: any[] = [];
        if (recurrentEvents.length === 0) {
          return overlappingRecurrentEvents;
        }

        recurrentEvents.forEach(recurrentEvent => {
          const recurrentEventStart = luxon.fromISO(recurrentEvent.start);
          const recurrentEventEnd = luxon.fromISO(recurrentEvent.stop);
          if (hasOverlap(recurrentEventStart, recurrentEventEnd, allEvents)) {
            overlappingRecurrentEvents.push({
              start: recurrentEventStart.toISO(),
              stop: recurrentEventEnd.toISO(),
            });
          }
        });
        return overlappingRecurrentEvents;
      }, []);

      const mergeWithoutDuplicates = (draftTimelines: ITimeline[], futureEvents: ITimeline[]) => {
        // Filter out duplicates from futureEvents
        const uniqueFutureEvents = futureEvents.filter(item => {
          return !draftTimelines.some(draft => draft.start === item.start && draft.stop === item.stop);
        });
        return [...draftTimelines, ...uniqueFutureEvents];
      };

      const timelineOnDrop = React.useCallback(
        (selected: ISelectedDrop[], timestamp: number) => {
          if (!selected || !selected.length || !timestamp) {
            return;
          }

          // check if the operation is about moving timelines around the schedule or dropping new ones from the queue
          const isDropping =
            !draftTimelines || !draftTimelines?.find(dt => selected.some(sel => dt.id === sel.id && sel.start));

          if (isDropping) {
            const selection = selectedQueue.length ? selectedQueue : selected;
            const droppedTimelines = selection.map(item =>
              findEpisodeInQueue({...item, episode: item.id}),
            ) as IChannelCatalogItem[];

            addEpisodesToCalendar(
              recalculateEventTimes(
                droppedTimelines,
                clipboardTimelinesRef.current || [],
                new Date(timestamp!),
                copyMode,
              ),
            );
          } else {
            // It is moving timelines position
            const selection = selected || selectedEpisodes;
            const movedTimelines = (draftTimelines || []).filter(dt =>
              selection.some(sel => dt.id === sel.id && sel.start),
            );

            let nextStartEpisode = timestamp;
            const newTimelines = movedTimelines.map(item => {
              const duration = item.episode.allotment * 1000;
              const timeline: ITimeline = {
                ...item,
                start: new Date(new Date(nextStartEpisode).setMilliseconds(0)),
                stop: new Date(nextStartEpisode + duration),
              };
              nextStartEpisode =
                copyMode === 'vertical-select'
                  ? nextStartEpisode + duration
                  : addDays(new Date(nextStartEpisode), 1).getTime();
              return timeline;
            });

            const existingWithoutMovedTimelines = (draftTimelines || []).filter(
              dt => !selection.some(sel => dt.id === sel.id && sel.start),
            );
            const allDraftTimelines = addEpisodes(newTimelines || [], existingWithoutMovedTimelines);
            const allDraftTimelinesSanitized = sanitizeDraftEventList(
              allDraftTimelines,
              getCurrentStartDate().start || new Date(),
              isSnapOn,
            );

            setDraftTimelinesToModel(allDraftTimelinesSanitized);
            setUndo(allDraftTimelinesSanitized);
          }
          handleCloseRightPanel();
        },
        [
          addEpisodesToCalendar,
          copyMode,
          draftTimelines,
          findEpisodeInQueue,
          getCurrentStartDate,
          handleCloseRightPanel,
          isSnapOn,
          selectedEpisodes,
          selectedQueue,
          setDraftTimelinesToModel,
          setUndo,
        ],
      );

      React.useEffect(() => {
        const checkRepeatScheduleResp = async () => {
          if (!recurrenceResponseFromModal || !lastRepeatSchedule) return;

          /**
           * Triggered when the search is successful
           * - check if there are overlapping events with the current timeline events
           * - if there are no overlapping events, add them to the calendar
           * - if there are overlapping events, display an error message
           */
          const {
            data: repeatScheduleItems,
            error: repeatScheduleError,
            originalArgs: repeatScheduleOriginalArgs,
          } = recurrenceResponseFromModal as any;

          // if isRepeatScheduleSuccess failed, run a toast error to inform the user there are no episodes to add
          if (repeatScheduleError) {
            const message =
              get(repeatScheduleError, 'error.data.message', null) ||
              get(repeatScheduleError, 'error.error.data.message', null) ||
              '';
            if ((message as string) === 'Timelines not found by channel, start and params') {
              Toast.error('There are no episodes to add to the Calendar');
            }
            setRecurrenceResponse(undefined);
            setComposedRecurrenceMessage(false);
            return;
          }

          if (repeatScheduleItems?.length && repeatScheduleOriginalArgs?.lastUpdate === lastRepeatSchedule) {
            // create an empty array to store the draft-timelines that are in the future
            const futureEvents: ITimeline[] = [];
            try {
              // fetch the draft-timelines that are in the future to check for overlapping events
              const startScheduleDate = new Date(repeatScheduleItems[0].start);

              // get the latest element of repeatScheduleItems to use it as stop
              const stopScheduleDate = new Date(repeatScheduleItems[repeatScheduleItems.length - 1].stop);

              let startScheduleTime: number | undefined;
              const stopScheduleTime = stopScheduleDate.getTime();

              // if recurrence starts in a future week
              if (endDate && startScheduleDate > endDate) {
                startScheduleTime = startScheduleDate.getTime();

                // if recurrence starts in the current week and ends in a future week
              } else if (endDate && stopScheduleDate > endDate) {
                startScheduleTime = endDate.getTime() + 1;
              }

              if (startScheduleTime && stopScheduleTime) {
                const resp = await summon.get<void, ITimeline[]>(
                  `channels/${channelId}/timeline-drafts?start=${startScheduleTime}&lastUpdate=${lastProgrammingUpdate.valueOf()}&stop=${stopScheduleTime}`,
                );

                if (!resp) return;
                // push the events, but parsing the start and stop for each item as Date
                futureEvents.push(
                  ...resp.map(item => {
                    return {
                      ...item,
                      start: new Date(item.start),
                      stop: new Date(item.stop),
                    };
                  }),
                );
              }
            } catch (e) {}
            // Concatenate draftTimelines with futureEvents
            const allDraftTimelines = mergeWithoutDuplicates(draftTimelines || [], futureEvents);
            const overlapingEvents = findOverlappingRecurrentEvents(allDraftTimelines, repeatScheduleItems);
            if (overlapingEvents.length > 0) {
              if (composedRecurrenceMessage) {
                // warn the user about the overlapping events
                Toast.warning(
                  '1 episode was added to the calendar. Overlapping events were detected and the Recurrence could not be fulfilled as configured.',
                );
              } else {
                Toast.error(
                  `There are ${overlapingEvents.length} events that overlap with the current timeline. Please remove them before adding the new events.`,
                );
              }
              setRecurrenceResponse(undefined);
              setComposedRecurrenceMessage(false);
              return;
            }

            let episodeIndex = (draftTimelines || []).length - 1;
            const newEvents = [
              ...(draftTimelines || []),
              ...(repeatScheduleItems || []).map(item => {
                const episode = findEpisodeInQueue(item);
                const start = new Date(item.start);

                episodeIndex = episodeIndex + 1;
                return {
                  ...item,
                  id: `${item.episode}-${episodeIndex}`,
                  start,
                  stop: new Date(item.stop),
                  locked:
                    start < getMinStart() || model.gracenoteIntegration?.enabled || model.cfaasIntegration?.enabled,
                  episode: episode || item.episode || {},
                  state: episode?.state,
                  stateMsgList: episode?.stateMsgList,
                  isRecurrenceEvent: true,
                  title: episode?.series.name,
                  subTitle: episode?.name,
                };
              }),
            ];

            const {start} = getCurrentStartDate();
            const allDraftTimelinesSanitized = sanitizeDraftEventList(newEvents, start, isSnapOn);
            setDraftTimelinesToModel(allDraftTimelinesSanitized);
            setUndo(allDraftTimelinesSanitized);

            // check if an initial episode was added to the calendar along with the recurrence
            const totalAdded = composedRecurrenceMessage ? repeatScheduleItems.length + 1 : repeatScheduleItems.length;
            Toast.success(`${totalAdded} Episodes were added to the Calendar`);
            setComposedRecurrenceMessage(false);
            setRecurrenceResponse(undefined);
          }
        };

        setTimeout(() => checkRepeatScheduleResp(), 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [recurrenceResponseFromModal, lastRepeatSchedule]);

      const showScreeningDate = React.useMemo(
        () => shouldOpenRightInfoPanel && selectedEpisodes.length === 1,
        [selectedEpisodes.length, shouldOpenRightInfoPanel],
      );

      const selectedItemsForDetails = React.useMemo(() => {
        if (shouldOpenRightInfoPanel) {
          return [...selectedEpisodes, ...selectedQueue];
        } else {
          return [];
        }
      }, [selectedEpisodes, selectedQueue, shouldOpenRightInfoPanel]);

      React.useEffect(() => {
        setIsRightExpanded(Boolean(selectedItemsForDetails.length) && shouldOpenRightInfoPanel);
      }, [selectedItemsForDetails.length, shouldOpenRightInfoPanel]);

      const onEntryClick = React.useCallback(
        (id, ev) => {
          if (!selectedItemsForDetails.length) {
            if ((ev.target as any)?.tagName.toLowerCase() === 'span') {
              // will open right panel if the user clicked on the episode/series name
              // and the panel is closed
              setShouldOpenRightInfoPanel(true);
            } else {
              isRightExpanded && setIsRightExpanded(false);
              shouldOpenRightInfoPanel && setShouldOpenRightInfoPanel(false);
            }
          }

          if (selectedQueue.length > 0) {
            setSelectedQueue([]);
          }
          handleClickItems([
            (isDraft ? draftTimelinesEntries || [] : publishedTimelinesEntries).findIndex(entry => entry.id === id),
          ]);
        },
        [
          draftTimelinesEntries,
          handleClickItems,
          isDraft,
          isRightExpanded,
          publishedTimelinesEntries,
          selectedItemsForDetails,
          selectedQueue,
          shouldOpenRightInfoPanel,
        ],
      );

      const onClickConflictGroup = React.useCallback(
        entries => {
          if (!selectedItemsForDetails.length) {
            setShouldOpenRightInfoPanel(true);
          }

          if (selectedQueue.length > 0) {
            setSelectedQueue([]);
          }

          const indexes: number[] = [];

          entries.forEach(e => {
            const entryIndex = (isDraft ? draftTimelinesEntries || [] : publishedTimelinesEntries).findIndex(
              entry => entry.id === e.id,
            );

            if (entryIndex > -1) {
              indexes.push(entryIndex);
            }
          });

          handleClickItems(indexes);
        },
        [
          draftTimelinesEntries,
          handleClickItems,
          isDraft,
          publishedTimelinesEntries,
          selectedItemsForDetails,
          selectedQueue,
        ],
      );

      const canDrop = React.useCallback(timestamp => new Date().valueOf() < timestamp, []);

      const onAddEntry = React.useCallback((timestamp: number) => {
        setTimeToAddNewEntry(timestamp);
        setIsAddEpisodeOpen(true);
        setSelectedEpisodes([]);
      }, []);

      const handleDrop = React.useCallback(() => {
        if (!ableTo('CHANNEL_EPG_EDIT') || !clipboardRef.current.length) return;

        addTimelinesToCalendar(clipboardRef.current, timelineRef.current.getTimestamp());
      }, [ableTo, addTimelinesToCalendar]);

      const preventAddTimeline = React.useMemo(
        () =>
          !isDraft ||
          !ableTo('CHANNEL_EPG_EDIT') ||
          model.gracenoteIntegration?.enabled ||
          model.cfaasIntegration?.enabled,
        [ableTo, isDraft, model],
      );

      const timelineEntries = React.useMemo(
        () => (isDraft ? draftTimelinesEntries || [] : publishedTimelinesEntries || []),
        [draftTimelinesEntries, isDraft, publishedTimelinesEntries],
      );

      const isTimelineLoading = React.useMemo(
        () => isScheduleLoading || !startDate || isDiscardTimelineDraftsLoading,
        [isDiscardTimelineDraftsLoading, isScheduleLoading, startDate],
      );

      const handleBulkRemove = React.useCallback(
        (entries: ISimpleScheduleEntry[]) => {
          const newArray = (draftTimelines || []).filter(item => !entries.find(e => e.id === item.id));

          const {start: currStartDate} = getCurrentStartDate();
          const allDraftTimelinesSanitized = sanitizeDraftEventList(newArray, currStartDate, isSnapOn);

          setDraftTimelinesToModel(allDraftTimelinesSanitized);

          setSelectedEpisodes(prevItems => prevItems.filter(item => !entries.find(e => item.id === e.id)));
          setUndo(allDraftTimelinesSanitized);
        },
        [draftTimelines, getCurrentStartDate, isSnapOn, setDraftTimelinesToModel, setUndo],
      );

      const estimateRowHeight = React.useCallback(() => 24.66, []);

      useKeyboardShortcut('ctrl+c, command+c', handleCopy);
      useKeyboardShortcut('ctrl+v, command+v', handleDrop);
      useKeyboardShortcut('ctrl+z, command+z', handleUndo);
      useKeyboardShortcut('shift+ctrl+z, shift+command+z', handleRedo);
      useKeyboardShortcut('delete, backspace', (event: KeyboardEvent) => {
        if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
          return;
        }
        handleDelete();
      });

      return (
        <>
          <React.Suspense fallback={<LoadingBox />}>
            <LeaveCalendarDialog
              programHasChanges={programHasChanges}
              isOpenLeaveDialog={isOpenLeaveDialog}
              onClickSaveHandler={onClickSaveHandler}
              attemptToLeave={attemptToLeave}
              cancelAttemptToLeave={cancelAttemptToLeave}
              setIsOpenLeaveDialog={setIsOpenLeaveDialog}
              moveToPeriod={moveToPeriod}
            />
          </React.Suspense>

          <Cluster growNthChild={2} fullHeight wrap={false} id='epg-queue-calendar-cluster'>
            <Expand
              isExpanded={isQueueExpanded && isDraft && ableTo('CHANNEL_EPG_EDIT')}
              fullHeightContainer
              height='100%'
              width='35vw'
            >
              <Template label='expandable'>
                <Box borderRight={true} borderSize='2px' borderColor='bone' fullHeight={true} background='pewter'>
                  <Box fullHeight paddingY='xxsmall' paddingX='xxsmall'>
                    <Cover scrolling={true} gutterTop='xxsmall' id='epg-queue' coverId='epg-queue-cover'>
                      <Template label='header'>
                        <Stack space='xxsmall'>
                          <Cluster justify='space-between' wrap={false} growNthChild={1}>
                            <Stack space='xxxsmall'>
                              <Heading level='h5' renderAs='tiny' color='secondary'>
                                Queue
                              </Heading>
                              <Heading level='h4' truncate={true} truncateBackgroundHover='pewter'>
                                {model.name}
                              </Heading>
                            </Stack>
                            <Stack space='xxxsmall'>
                              <Paragraph size='small' color='secondary' textAlign='right'>
                                Total Episodes: {queueTotalItems}
                              </Paragraph>
                              <Paragraph size='small' color='secondary' textAlign='right'>
                                Total Hours: {isQueueLoading ? '' : secondsToHms(queueDuration || -1)}
                              </Paragraph>
                            </Stack>
                          </Cluster>
                          <Divider color='graphite' />
                        </Stack>
                      </Template>
                      <Template label='cover'>
                        {isQueueExpanded && (
                          <>
                            <Table
                              size='tiny'
                              draggable={true}
                              onSelect={rows => {
                                setSelectedEpisodes(rows as any);
                                handleClickQueueEntries([]);
                              }}
                              selectable='multiple'
                              onSort={queueOnSort}
                              sortDir={queueSort.split(':')[1] as 'asc' | 'dsc'}
                              sortCol={TableColumnLabel[queueSort.split(':')[0]]}
                              selected={selectedEpisodes as any}
                              onRowClick={onClickQueueTableRow}
                              wrapContent={true}
                              overflowWrap={true}
                              cols={[
                                {
                                  label: '',
                                  transform: row => <RowState row={row} />,
                                  colWidth: '1.25rem',
                                  colMinWidth: '1.25rem',
                                  zeroRightPadding: true,
                                },
                                {
                                  label: 'Author',
                                  sortable: true,
                                  colWidth: '20%',
                                  transform: (row, col, index) => (
                                    <TdMultilineTruncate
                                      row={row}
                                      onClick={() => onClickQueueTableRow(row, index)}
                                      truncateOnLine={2}
                                      text={row.author || ''}
                                    />
                                  ),
                                },
                                {
                                  label: 'Series',
                                  sortable: true,
                                  colWidth: '20%',
                                  transform: (row, col, index) => (
                                    <TdMultilineTruncate
                                      row={row}
                                      onClick={() => onClickQueueTableRow(row, index)}
                                      truncateOnLine={2}
                                      text={row.series?.name || ''}
                                    />
                                  ),
                                },
                                {
                                  label: 'Episode',
                                  sortable: true,
                                  colWidth: '20%',
                                  transform: (row, col, index) => (
                                    <TdMultilineTruncate
                                      row={row}
                                      onClick={() => onClickQueueTableRow(row, index)}
                                      truncateOnLine={2}
                                      text={row.name || ''}
                                    />
                                  ),
                                },
                                {
                                  label: 'S',
                                  colWidth: '3rem',
                                  sortable: true,
                                  field: 'season',
                                },
                                {
                                  label: 'E',
                                  colWidth: '3rem',
                                  sortable: true,
                                  field: 'number',
                                },
                                {
                                  label: 'Rat.',
                                  sortable: true,
                                  colWidth: '3rem',
                                  colMinWidth: '3rem',
                                  field: 'rating',
                                },
                                {
                                  label: 'Dur.',
                                  sortable: true,
                                  colWidth: '3.7rem',
                                  colMinWidth: '3.7rem',
                                  transform: row => secondsToHms(row.duration!),
                                },
                              ]}
                              rows={queueItemsRetrieved}
                              loading={isQueueLoading}
                              lazyLoading={isQueueLazyLoading}
                              onLazyLoad={handleQueueLazyLoad}
                              virtual={true}
                              estimateItemHeight={estimateRowHeight}
                              predicate='id'
                              onCustomRowDrag={onCustomRowDrag}
                            ></Table>
                          </>
                        )}
                      </Template>
                    </Cover>
                  </Box>
                </Box>
              </Template>
            </Expand>
            <Box fullHeight background='pewter' overflow='auto' id='epg-box'>
              <Cover scrolling={true} coverId='epg-cover'>
                <Template label='header'>
                  <Box paddingX='small'>
                    <Cluster align='center' justify='space-between' wrap={false}>
                      {canModifyEdit ? (
                        <Box id='showQueue'>
                          <Box hideAt='desktopAndUp'>
                            <Icon
                              space='xxsmall'
                              icon='collapseleft'
                              rotate={isQueueExpanded ? '' : '180deg'}
                              size='large'
                              onClick={() => setIsQueueExpanded(!isQueueExpanded)}
                            />
                          </Box>
                          <Box hideAt='laptopAndDown'>
                            <Icon
                              space='xxsmall'
                              icon='collapseleft'
                              iconAlign='center'
                              color='white'
                              textColor='neutral'
                              rotate={isQueueExpanded ? '' : '180deg'}
                              size='large'
                              onClick={() => setIsQueueExpanded(!isQueueExpanded)}
                            >
                              {isQueueExpanded ? 'Hide' : 'Show'} Queue
                            </Icon>
                          </Box>
                        </Box>
                      ) : (
                        <Box width='10.1875rem'></Box>
                      )}
                      <Cluster
                        space={{laptop: 'xxsmall', desktop: 'medium', wide: 'xlarge'}}
                        align='center'
                        justify='center'
                        wrap={true}
                        id='epg-header'
                      >
                        {!isDraft && (
                          <Button id='printCalendar' icon='print' onClick={() => window.print()}>
                            Print
                          </Button>
                        )}
                        {canModifyEdit && (
                          <Box minWidth='3rem'>
                            <Select
                              id='daysNumber'
                              size='small'
                              value={{label: daysToAutofillSelected.toString()}}
                              options={autofillDaysOptions}
                              onChange={value => setDaysToAutofillSelected(value.value)}
                              state={model.gracenoteIntegration?.enabled ? 'disabled' : ''}
                            />
                          </Box>
                        )}
                        {canModifyEdit && (
                          <Popover
                            appendToBody={true}
                            manualTrigger={true}
                            visible={
                              !existGapsInTimeline &&
                              openMsgWhenAutofillDisabled &&
                              !isDraftTimelinesLoading &&
                              !isQueueLoading
                            }
                          >
                            <Template label='trigger'>
                              <div
                                onMouseOver={() => (!existGapsInTimeline ? setOpenMsgWhenAutofillDisabled(true) : null)}
                                onMouseLeave={() =>
                                  !existGapsInTimeline ? setOpenMsgWhenAutofillDisabled(false) : null
                                }
                              >
                                <Button
                                  id='autoFillButton'
                                  onClick={autofill}
                                  permission={permissions.CHANNEL_EPG_EDIT}
                                  type='primary'
                                  state={
                                    !existGapsInTimeline || isPageLoading || model.gracenoteIntegration?.enabled
                                      ? 'disabled'
                                      : isAddingQueueAutofill
                                      ? 'thinking'
                                      : ''
                                  }
                                >
                                  Auto Fill
                                </Button>
                              </div>
                            </Template>
                            <Template label='popover'>
                              <Box paddingX='small' paddingY='xxxxsmall' background='charcoal'>
                                <Stack>
                                  <Icon space='xxsmall' size='small' icon='info' color='info' iconAlign='center'>
                                    <Help>There are no empty slots in the calendar week to auto fill.</Help>
                                  </Icon>
                                </Stack>
                              </Box>
                            </Template>
                          </Popover>
                        )}
                        {canModifyEdit && (
                          <Box>
                            <Checkbox
                              id='snapOn'
                              label='Snap On'
                              value={isSnapOn}
                              onChange={() => setIsSnapOn(!isSnapOn)}
                              state={isPageLoading ? 'disabled' : ''}
                            />
                          </Box>
                        )}
                        {canModifyEdit && (
                          <Box minWidth='5.5rem'>
                            <Select
                              id='incrementTime'
                              size='small'
                              predicate='value'
                              value={{value: increment, label: ''}}
                              options={[
                                {label: '1 Min.', value: 'small'},
                                {label: '15 Min.', value: 'medium'},
                              ]}
                              onChange={val => setIncrement(val.value)}
                            />
                          </Box>
                        )}
                        {canModifyEdit && (
                          <Box minWidth='5rem'>
                            <Select
                              id='positioning'
                              size='small'
                              predicate='value'
                              value={{value: copyMode, label: ''}}
                              options={[
                                {label: 'Vert.', value: 'vertical-select'},
                                {label: 'Horiz.', value: 'horizontal-select'},
                                {label: 'Group', value: 'group-select'},
                              ]}
                              onChange={val => handleCopyMode(val.value)}
                            />
                          </Box>
                        )}
                        <Box minWidth='7rem' maxWidth='9.0rem'>
                          <Cluster space='small' wrap={false} align='center'>
                            <Label>Zoom</Label>
                            <Range id='zoom-slider' min={0} max={2} value={zoomLevel} onChange={debouncedZoom} />
                          </Cluster>
                        </Box>
                        <Box minWidth='4rem'>
                          <Cluster buttonGroup={true} wrap={false}>
                            <Button
                              id='dayCalendar'
                              active={viewType === 'day' ? true : false}
                              onClick={() => switchView('day')}
                            >
                              Day
                            </Button>
                            <Button
                              id='weekCalendar'
                              active={viewType === 'week' ? true : false}
                              onClick={() => switchView('week')}
                            >
                              Week
                            </Button>
                          </Cluster>
                        </Box>
                      </Cluster>
                      {isDraft && (
                        <Button
                          type='secondary'
                          onClick={() => {
                            setIsDraft(false);
                          }}
                          id='viewPublished'
                          permission={permissions.CHANNEL_EPG_VIEW}
                        >
                          View Pub. Calendar
                        </Button>
                      )}
                      {!isDraft && (
                        <Button
                          type='secondary'
                          onClick={() => setIsDraft(true)}
                          id='viewDraft'
                          permission={permissions.CHANNEL_EPG_VIEW}
                        >
                          View Draft Calendar
                        </Button>
                      )}
                    </Cluster>
                  </Box>
                </Template>
                <Template label='cover'>
                  <Timeline
                    id='epg'
                    loading={isTimelineLoading}
                    selected={selectedEpisodes as ISimpleScheduleEntry[]}
                    onPaste={handleDrop}
                    preventAdd={preventAddTimeline}
                    ref={timelineRef}
                    days={viewType === 'day' ? 1 : 7}
                    startDate={dayValue}
                    zoomLevel={zoomLevel}
                    type='schedule'
                    intervals={increment}
                    entries={timelineEntries}
                    entryMinWidth='7rem'
                    canDrop={canDrop}
                    onClearConflictGroup={handleBulkRemove}
                    onClickConflictGroup={onClickConflictGroup}
                    onEntryClick={onEntryClick}
                    onAddEntry={onAddEntry}
                    onDrop={timelineOnDrop}
                    transformEntry={transformEntry}
                    transformTopEntry={transformTopEntry}
                    onHandleWhenClickOutsideAnEntry={handleCloseRightPanel}
                  />
                </Template>
              </Cover>
            </Box>
            <Drawer isOpen={isRightExpanded} onClose={() => handleCloseRightPanel()} width='33rem'>
              <Box paddingY='medium' paddingRight='medium' paddingLeft='medium' fullHeight>
                <Cover scrolling={true} gutterTop='large'>
                  <Template label='header'>
                    <Stack space='large'>
                      <Stack space='xxsmall'>
                        {selectedItemsForDetails.length === 1 && (
                          <Box paddingRight='large'>
                            <Heading level='h3' truncate truncateBackgroundHover='cavern'>
                              {selectedItemsForDetails[0].name}
                            </Heading>
                          </Box>
                        )}
                        {selectedItemsForDetails.length > 1 && (
                          <Heading level='h3' truncate truncateBackgroundHover='cavern'>
                            {`(${selectedItemsForDetails.length}) Episodes Selected`}
                          </Heading>
                        )}
                        {showScreeningDate && (
                          <Paragraph color='secondary' size='small'>
                            {formatScreeningTime(
                              isSimpleScheduleEntry(selectedItemsForDetails[0])
                                ? selectedItemsForDetails[0].start
                                : undefined,
                              selectedItemsForDetails[0].allotment as number,
                            )}
                          </Paragraph>
                        )}
                      </Stack>
                      <Divider color='graphite' />
                      <Cluster buttonGroup={true} justify='center'>
                        <Button
                          active={episodeInfo === 'details' ? true : false}
                          onClick={() => setEpisodeInfo('details')}
                        >
                          Details
                        </Button>
                        {selectedItemsForDetails.length === 1 && (
                          <Button
                            active={episodeInfo === 'recurrence' ? true : false}
                            onClick={() => setEpisodeInfo('recurrence')}
                            permission={permissions.CHANNEL_EPG_EDIT}
                          >
                            Recurrence
                          </Button>
                        )}
                      </Cluster>
                    </Stack>
                  </Template>
                  <Template label='cover'>
                    {episodeInfo === 'details' && selectedItemsForDetails.length > 0 && (
                      <EpisodesDetails
                        items={selectedItemsForDetails}
                        onRemove={isDraft ? handleRemove : undefined}
                        onError={handleCloseRightPanel}
                      />
                    )}
                    {episodeInfo === 'recurrence' && (
                      <Stack space='xlarge'>
                        <Heading level='h3' color='secondary'>
                          Set Recurrence
                        </Heading>
                        <Stack space='medium'>
                          <Heading level='h4'>Repeat</Heading>
                          <Grid minimum='11.25rem' gap='medium'>
                            <FormItem
                              label='Repeat Every'
                              helpText={recurrenceWarning.timeframe}
                              state={recurrenceWarning.timeframe ? 'error' : ''}
                              onFocus={() => {
                                setIsEditingRecurrenceField(true);
                              }}
                              onBlur={() => {
                                setIsEditingRecurrenceField(false);
                              }}
                            >
                              <TextInput
                                type='number'
                                value={repeatPeriod}
                                onChange={value => {
                                  setRepeatPeriod(value);
                                  setIsEditingRecurrenceField(true);
                                }}
                              />
                            </FormItem>
                            <FormItem label='Timeframe'>
                              <Select
                                predicate='value'
                                options={[
                                  {label: 'Day(s)', value: 'day'},
                                  {label: 'Week(s)', value: 'week'},
                                  {label: 'Month(s)', value: 'month'},
                                ]}
                                value={{label: '', value: timeframe}}
                                onChange={value => {
                                  setTimeframe(value.value);
                                }}
                              />
                            </FormItem>
                          </Grid>
                          {timeframe === 'week' && (
                            <Stack space='small'>
                              <Label>Repeat on which day(s) of the week</Label>
                              <Cluster buttonGroup={true}>
                                <Button active={sunRepeat} onClick={() => setSunRepeat(!sunRepeat)}>
                                  Sun
                                </Button>
                                <Button active={monRepeat} onClick={() => setMonRepeat(!monRepeat)}>
                                  Mon
                                </Button>
                                <Button active={tueRepeat} onClick={() => setTueRepeat(!tueRepeat)}>
                                  Tue
                                </Button>
                                <Button active={wedRepeat} onClick={() => setWedRepeat(!wedRepeat)}>
                                  Wed
                                </Button>
                                <Button active={thuRepeat} onClick={() => setThuRepeat(!thuRepeat)}>
                                  Thu
                                </Button>
                                <Button active={friRepeat} onClick={() => setFriRepeat(!friRepeat)}>
                                  Fri
                                </Button>
                                <Button active={satRepeat} onClick={() => setSatRepeat(!satRepeat)}>
                                  Sat
                                </Button>
                              </Cluster>
                              {/** Provides some extra space */}
                              <Box height='0.625rem'></Box>
                            </Stack>
                          )}
                          {timeframe === 'month' && (
                            <FormItem label='Positioning'>
                              <Select
                                predicate='value'
                                options={[
                                  {label: 'Same Day of the Week', value: 'week'},
                                  {label: 'Same Day of the Month', value: 'month'},
                                ]}
                                value={{label: '', value: positioning}}
                                onChange={opt => {
                                  setPositioning(opt.value);
                                }}
                              />
                            </FormItem>
                          )}
                        </Stack>
                        <Stack space='medium'>
                          <Heading level='h4'>End Repeat</Heading>
                          <FormItem label='End Type'>
                            <Select
                              predicate='value'
                              options={[
                                {label: 'End On Date', value: 'date'},
                                {label: 'End On Occurence', value: 'after'},
                              ]}
                              value={{label: '', value: endRepeatType}}
                              onChange={value => {
                                setEndRepeatType(value.value);
                              }}
                            />
                          </FormItem>
                          {endRepeatType === 'date' && (
                            <div
                              onBlur={() => {
                                setIsEditingRecurrenceField(false);
                              }}
                              onInput={() => {
                                setIsEditingRecurrenceField(true);
                              }}
                              onFocus={() => {
                                setIsEditingRecurrenceField(true);
                              }}
                            >
                              <FormItem
                                label='Date'
                                helpText={recurrenceWarning.occurrences}
                                state={recurrenceWarning.occurrences ? 'error' : ''}
                              >
                                <DateTime
                                  id='endRecurrenceDate'
                                  onBeforeDateRender={date => disablePastDates(date, startDate)}
                                  value={endRepeatDate}
                                  onChange={val => {
                                    setEndRepeatDate(val as Date);
                                    setIsEditingRecurrenceField(true);
                                  }}
                                />
                              </FormItem>
                            </div>
                          )}
                          {endRepeatType === 'after' && (
                            <FormItem
                              label='End after how many occurrences'
                              helpText={recurrenceWarning.occurrences}
                              state={recurrenceWarning.occurrences ? 'error' : ''}
                              onFocus={() => {
                                setIsEditingRecurrenceField(true);
                              }}
                              onBlur={() => {
                                setIsEditingRecurrenceField(false);
                              }}
                            >
                              <TextInput type='number' value={occurrences} onChange={value => setOccurrences(value)} />
                            </FormItem>
                          )}
                        </Stack>
                        <Stack space='medium'>
                          <Heading level='h4'>Cycle</Heading>
                          <FormItem label='Cycle Over'>
                            <Select
                              predicate='value'
                              options={[
                                {label: 'Episodes and Seasons in the Series', value: 'series'},
                                {label: 'Episode in this Season', value: 'season'},
                              ]}
                              value={{label: '', value: cycleOver}}
                              onChange={value => {
                                setCycleOver(value.value);
                              }}
                            />
                          </FormItem>
                        </Stack>
                        <Cluster justify='space-between'>
                          <div></div>
                          <Button
                            type='primary'
                            state={isValidRecurrence ? '' : 'disabled'}
                            onClick={() =>
                              handleAddRepeatSchedule(selectedEpisodes.length ? selectedEpisodes[0] : selectedQueue[0])
                            }
                          >
                            Apply
                          </Button>
                        </Cluster>
                      </Stack>
                    )}
                  </Template>
                </Cover>
              </Box>
            </Drawer>
          </Cluster>
          {isAddEpisodeOpen && (
            <React.Suspense fallback={<React.Fragment />}>
              <EpisodeQueueSearchModal
                isAddEpisodeOpen={isAddEpisodeOpen}
                setIsAddEpisodeOpen={setIsAddEpisodeOpen}
                isRecurrenceOpen={isRecurrenceOpen}
                setIsRecurrenceOpen={setIsRecurrenceOpen}
                addEpisodesToCalendar={addSeriesToCalendar}
                setComposedRecurrenceMessage={setComposedRecurrenceMessage}
                channelId={channelId}
                startDate={startDate}
                setRecurrenceResponse={setRecurrenceResponse}
                timeToAddNewEntry={timeToAddNewEntry}
                setLastRepeatSchedule={setLastRepeatSchedule}
              />
            </React.Suspense>
          )}
          {isRecurrenceOpen && selectedEpisodes.length === 1 && (
            <EpisodeRecurrence
              channelId={channelId}
              startDate={startDate}
              searchSelected={selectedEpisodes[0]}
              setIsRecurrenceOpen={setIsRecurrenceOpen}
              isRecurrenceOpen={isRecurrenceOpen}
              setRecurrenceResponse={setRecurrenceResponse}
              setLastRepeatSchedule={setLastRepeatSchedule}
            />
          )}
        </>
      );
    },
  ),
);

const EpisodeTemplate = ({item}: {item: IScheduleEntry}): JSX.Element => (
  <div className='template-test'>
    <Stack space='xxxxsmall'>
      <div className='print-margin'>
        <Heading level='h6' renderAs='tiny' color='secondary'>
          {item.author}
        </Heading>
      </div>
      <div className='print-margin'>
        <Heading level='h6' renderAs='tiny' color='secondary'>
          (S{item.season}: E{item.number}) {item.rating}
        </Heading>
      </div>
    </Stack>
  </div>
);

const EpisodeTopTemplate = ({item}: {item: IScheduleEntry}) => {
  const start = new Date(item.start);
  const stopMs = start.getTime() + item.duration * 1000;
  const stop = new Date(stopMs);

  return (
    <Heading level='h6' renderAs='tiny' color='secondary'>
      {`${formatDateWithTimezone(start, 'h:mm a')} - ${formatDateWithTimezone(stop, 'h:mm a')}`}
    </Heading>
  );
};

export default ProgramPage;
