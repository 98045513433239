import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Box,
  Button,
  Click,
  Cluster,
  Cover,
  Dialog,
  Divider,
  FormItem,
  Heading,
  ISimpleEntry,
  Icon,
  Paragraph,
  Popover,
  Range,
  Stack,
  TSize,
  Table,
  TdMultilineTruncate,
  Template,
  TextInput,
  Timeline,
  isValEmpty,
  ZOOM_LEVELS,
  secondsToHms,
  hmsToSeconds,
  TIcons,
  Toast,
  summon,
  Spinner,
  ISimpleAdPod,
  ITableCol,
  TZoomLevelsTimeline,
  useUndo,
  ITableRef,
  TSmallCellPaddingTop,
  TSmallCellPaddingBottom,
  useDelayedState,
  isBrowser,
} from '@pluto-tv/assemble';
import {cloneDeep, debounce, isNumber, shuffle, uniqBy, orderBy, times} from 'lodash-es';

import {TableActions} from 'components/tableActions';
import DeleteConfirmation from 'components/deleteConfirmation';
import ClipList from 'components/clipList';
import PlaylistPlayer, {IPlaylistPlayerRef} from 'components/playlistPlayer';

import {isDropFrame, msToTimecode, timecodeToMs} from 'helpers/msToTimecode';
import {IPopover} from 'helpers/popoverInterface';
import {useSearchParams} from 'helpers/useSearchQuery';
import {mapPopulatedEpisodeToEpisode} from 'helpers/mapPopulatedEpisode';
import {popoverActionsProps} from 'helpers/popoverActionProps';
import {reorderList} from 'helpers/dragAndDrop';

import {IClip} from 'models/clips';
import {IEpisode, IEpisodeSource} from 'models/episodes';
import {TSortDirection} from 'models/generic';

import {useKeyboardShortcut} from 'views/programming/channel/edit/program/hooks/useKeyboardShortcut';

import {calcMarkerOffset, onDropClip, setActive, transformClipToEntry, transformClips} from './workers';
import AdPodForm, {IAdPodValue} from './adPodForm';

import {INestedEpisodeProps} from '../nestedPropsInterface';
import {useEpisodePermissions} from '../../permissions/useEpisodePermissions';
import contentRoutes from 'routes/content.routes';
import {getPrefixedUrl} from 'routes';

const css = `
  #timelineContainer {
    margin-right: 1.375rem;
  }
`;

interface IPlaceholder {
  mask: string;
  placeholder: string;
}

interface IMagicWand extends Partial<IEpisode> {
  adPodsDuration: number;
}

const dropFramePlaceholder: IPlaceholder = {
  mask: 'NN:[0-5]N:[0-5]N;NN',
  placeholder: '--:--:--;--',
};

const nonDropFramePlaceholder: IPlaceholder = {
  mask: 'NN:[0-5]N:[0-5]N:NN',
  placeholder: '--:--:--:--',
};

export interface IEpisodeVideoRef {
  getDirtySources(): Set<string>;
  isSourceDirty(id: string): boolean;
  resetDirtySources(): void;
}

const TABLE_COLUMN_NAME = {
  Name: 'title',
  Author: 'author',
  'Created At': 'createdAt',
} as const;

const EpisodeVideo = React.forwardRef<IEpisodeVideoRef, INestedEpisodeProps>(
  ({model, pristineModel, setFields, setHasInOutPointWarning}: INestedEpisodeProps, ref) => {
    const {CAN_EDIT, editPermission} = useEpisodePermissions(pristineModel);

    const search = useSearchParams();
    const history = useHistory();

    const [isAddClipOpen, setIsAddClipOpen] = React.useState<boolean>(search.get('addClip') ? true : false);
    const [clipView, setClipView] = React.useState('table');

    const dirtySourcesRef = React.useRef<Set<string>>(new Set());

    React.useEffect(() => {
      if (search.get('addClip')) {
        setIsAddClipOpen(true);
        history.replace({});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    React.useImperativeHandle(ref, () => ({
      getDirtySources: () => dirtySourcesRef.current,
      isSourceDirty: (id: string) => dirtySourcesRef.current.has(id),
      resetDirtySources: () => dirtySourcesRef.current.clear(),
    }));

    const [isRemoveAllOpen, setIsRemoveAllOpen] = React.useState(false);
    const [isRemoveClipOpen, setIsRemoveClipOpen] = React.useState(false);
    const [isEditDurationOpen, setIsEditDurationOpen] = React.useState(false);

    const [adPodsPopoverOpen, setAdPodsPopoverOpen] = React.useState<IPopover>({});
    const [adPodsDeletePopoverOpen, setAdPodsDeletePopoverOpen] = React.useState<boolean>();

    const [zoomLevel, setZoomLevel] = React.useState<TZoomLevelsTimeline>(5);

    const [workingClips, setWorkingClips] = React.useState<ISimpleEntry[]>([]);
    const [activeClip, setActiveClip] = React.useState<ISimpleEntry>();
    const [activeClipIndex, setActiveClipIndex] = React.useState<number>();
    const [selectedClips, setSelectedClips] = React.useState<number[]>([]);
    const [selectedAdPods, setSelectedAdPods] = React.useState<ISimpleAdPod[]>([]);
    const [highlightNewRowsIndex, setHighlightNewRowsIndex] = React.useState<number[]>([]);

    const [editAllotment, setEditAllotment] = React.useState<string>();

    const [inPoint, setInPoint] = React.useState<string>();
    const [inPointError, setInPointError] = React.useState<string>();
    const [outPoint, setOutPoint] = React.useState<string>();
    const [outPointError, setOutPointError] = React.useState<string>();

    const [markerOffset, setMarkerOffset] = React.useState<TSize>();

    const [selectedAddClip, setSelectedAddClip] = React.useState<IClip[]>();

    const [magicWandLoading, setMagicWandLoading] = React.useState<boolean>(false);
    const [autoResizeLoading, setAutoResizeLoading] = React.useState<boolean>(false);

    const [sortCol, setSortCol] = React.useState<keyof typeof TABLE_COLUMN_NAME>();
    const [sortDir, setSortDir] = React.useState<TSortDirection>();

    const [playerError, setPlayerError] = React.useState<boolean>(true);
    const [hasModifiedInOutPoint, setHasModifiedInOutPoint] = React.useState<boolean>(false);

    const changeSort = (columnName: keyof typeof TABLE_COLUMN_NAME) => {
      const newSortDir: TSortDirection = columnName === sortCol ? (sortDir === 'asc' ? 'dsc' : 'asc') : 'dsc';

      const newWorkingClips = orderBy(
        workingClips,
        [TABLE_COLUMN_NAME[columnName]],
        sortDir === 'asc' ? 'asc' : 'desc',
      );

      setTimeRowsLoading(true);

      setSortCol(columnName);
      setSortDir(newSortDir);
      setWorkingClips(newWorkingClips);
      setSelectedClips([]);

      setTimeout(() => {
        updateTimeRows();
        updateTimeRows.flush();
      }, 50);
    };

    const playerRef = React.useRef<IPlaylistPlayerRef>(null);
    const tableRef = React.useRef<ITableRef>(null);
    const boxRef = React.useRef<HTMLDivElement>(null);

    const [timeRows, setTimeRows] = React.useState<any[]>([]);
    const [timeRowsLoading, setTimeRowsLoading] = useDelayedState(true, 250);

    const updateTimeRows = React.useMemo(
      () =>
        debounce(
          () => {
            let runningTotal = 0;

            const heights = tableRef.current?.getRowSize();

            if (!heights) {
              return;
            }

            const workingTimeRows = (workingClips || []).map((clip, i) => {
              const timestamp = secondsToHms(runningTotal);
              runningTotal += clip.duration;

              const height = heights ? heights[i] : 0;
              const heightCalc = `calc(${height}px - ${TSmallCellPaddingTop} - ${TSmallCellPaddingBottom})`;

              return {
                timestamp,
                height: heightCalc,
              };
            });

            setTimeRows(workingTimeRows);
            setTimeRowsLoading(false);
          },
          200,
          {
            leading: true,
            trailing: true,
          },
        ),
      [workingClips, setTimeRowsLoading],
    );

    React.useEffect(() => {
      if (clipView === 'table') {
        setTimeout(() => {
          updateTimeRows();
          updateTimeRows.flush();
        }, 250);
      }
    }, [workingClips, updateTimeRows, setTimeRowsLoading, clipView]);

    React.useLayoutEffect(() => {
      let isMounted = true;

      let observer: ResizeObserver;

      if (isBrowser && typeof window?.ResizeObserver !== 'undefined') {
        observer = new window.ResizeObserver(([entry]) => {
          window.requestAnimationFrame(() => {
            if (isMounted && entry) {
              updateTimeRows();
            }
          });
        });

        observer.observe(boxRef.current as Element);
      }

      return () => {
        isMounted = false;
        observer?.disconnect();
      };
    }, [setTimeRowsLoading, updateTimeRows]);

    const {set: setUndo, undo, redo, canUndo, canRedo} = useUndo<ISimpleEntry[]>();

    const handleUndo = React.useCallback(() => {
      if (!CAN_EDIT || !canUndo()) return;

      const previousClips = undo();

      if (previousClips) {
        setWorkingClips(previousClips);
      }
    }, [CAN_EDIT, canUndo, undo]);

    const handleRedo = React.useCallback(() => {
      if (!CAN_EDIT || !canRedo()) return;

      const clips = redo();

      if (clips) {
        setWorkingClips(clips);
      }
    }, [CAN_EDIT, canRedo, redo]);

    // Create new ad pod
    useKeyboardShortcut('ctrl + e, command + e', event => {
      event.preventDefault();

      if (!CAN_EDIT || !Number.isFinite(activeClipIndex) || !allowToAddClip) {
        return;
      }

      const cloned = cloneDeep(activeClip?.adPods || []);

      cloned.push({
        duration: 120,
        startAt: playerRef.current?.getCurrentTime()[0] || 0,
      });

      const uniqPods = uniqBy(cloned, (pod: IAdPodValue) => pod.startAt);

      // There is a duplicate of startAt somewhere
      if (uniqPods.length !== cloned.length) {
        Toast.error('Start times need to be unique. Please try again');
        return;
      }

      dirtySourcesRef.current.add(workingClips[activeClipIndex!].id);

      const newWorkingClips = workingClips.map((source, i) => ({
        ...source,
        ...(i === activeClipIndex && {
          adPods: cloned.sort((a, b) => a.startAt - b.startAt),
        }),
      }));

      setUndo(newWorkingClips);
      setWorkingClips(newWorkingClips);
    });

    // Delete clip
    useKeyboardShortcut('delete', event => {
      event.preventDefault();

      if (!CAN_EDIT || !Number.isFinite(activeClipIndex)) {
        return;
      }

      if (selectedClips.length > 0) {
        handleRemoveClips();
      } else {
        removeClip();
      }
    });

    // Undo / Redo
    useKeyboardShortcut('ctrl+z, command+z', handleUndo);
    useKeyboardShortcut('shift+ctrl+z, shift+command+z', handleRedo);

    React.useEffect(() => {
      if (!pristineModel) {
        return;
      }

      (async () => {
        try {
          const transformedClips = await transformClips(pristineModel?.sources);

          if (transformedClips.length > 0) {
            const activeId = activeClipIndex ? activeClipIndex : 0;
            const updatedClips = await setActive(transformedClips, activeId);

            setUndo(updatedClips, {locked: true});
            setWorkingClips(updatedClips);
            setActiveClipIndex(activeId);
          } else {
            setUndo([], {locked: true});
            setWorkingClips([]);
          }
        } catch (e) {
          setUndo([], {locked: true});
          setWorkingClips([]);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pristineModel]);

    React.useEffect(() => {
      if (Number.isFinite(activeClipIndex) && workingClips[activeClipIndex!]) {
        setActiveClip(workingClips[activeClipIndex!]);
      } else {
        setActiveClip(undefined);
      }
    }, [workingClips, activeClipIndex]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => workingClipsChanged(), [workingClips]);

    // Update the new rows index array to clean after 1.5 seconds
    React.useEffect(() => {
      let isMounted = true;

      if (highlightNewRowsIndex.length > 0) {
        setTimeout(() => {
          if (isMounted) {
            setHighlightNewRowsIndex([]);
          }
        }, 1500);
      }

      return () => {
        isMounted = false;
      };
    }, [highlightNewRowsIndex]);

    const videoSeek = React.useCallback(async (offsetSeconds: number) => {
      if (playerRef.current) {
        const [timestamp] = await playerRef.current.getCurrentTimeline();
        playerRef.current.seekTo(timestamp + offsetSeconds);
      }
    }, []);

    const addPinnedAdPod = React.useCallback(async () => {
      if (playerRef.current) {
        const [timestamp] = await playerRef.current.getCurrentTimeline();

        const cloned = cloneDeep(activeClip?.adPods || []);

        if (inPoint) {
          if (timecodeToMs(inPoint, activeClip?.meta.clip.framerate) > timestamp * 1000) {
            Toast.error('Ad Pod must start after In Point');
            return;
          }
        }

        if (outPoint) {
          if (timecodeToMs(outPoint, activeClip?.meta.clip.framerate) < timestamp * 1000) {
            Toast.error('Ad Pod must start before Out Point');
            return;
          }
        }

        cloned.push({
          duration: 120,
          startAt: timestamp,
        });

        const uniqPods = uniqBy(cloned, (pod: IAdPodValue) => pod.startAt);

        // There is a duplicate of startAt somewhere
        if (uniqPods.length !== cloned.length) {
          Toast.error('Start times need to be unique. Please try again');
          return;
        }

        if (Number.isFinite(activeClipIndex)) {
          dirtySourcesRef.current.add(workingClips[activeClipIndex!].id);
        }

        const newWorkingClips = workingClips.map((source, i) => ({
          ...source,
          ...(i === activeClipIndex && {
            adPods: cloned.sort((a, b) => a.startAt - b.startAt),
          }),
        }));

        setUndo(newWorkingClips);
        setWorkingClips(newWorkingClips);

        setAdPodsPopoverOpen({});
      }
    }, [activeClip, activeClipIndex, setUndo, workingClips, inPoint, outPoint]);

    // Explicitly set the error to true when the active clip index changes
    // This handles issues when the player doesn't report an error but playback has still failed
    React.useEffect(() => {
      setPlayerError(true);
    }, [activeClipIndex]);

    const onError = React.useCallback(() => setPlayerError(true), []);
    const onLoad = React.useCallback(() => setPlayerError(false), []);

    const removeClip = React.useCallback(async () => {
      let filteredClips = cloneDeep(workingClips).filter((source, index) => index !== activeClipIndex);
      let newIndex: number | undefined = undefined;

      if (Number.isFinite(activeClipIndex)) {
        const updatedIndex = activeClipIndex! - 1 > -1 ? activeClipIndex! - 1 : 0;

        if (filteredClips[updatedIndex]) {
          filteredClips = await setActive(filteredClips, updatedIndex);
          newIndex = updatedIndex;
        }
      }

      setUndo(filteredClips);
      setWorkingClips(filteredClips);
      setActiveClipIndex(newIndex);
      setSelectedClips([]);
    }, [activeClipIndex, setUndo, workingClips]);

    const handleRemoveClips = React.useCallback(() => {
      if (autoResizeLoading || magicWandLoading) {
        return;
      }

      const filteredClips = workingClips.filter((clip, i) => !selectedClips.some(sc => sc === i));

      setUndo(filteredClips);
      setWorkingClips(filteredClips);

      if (filteredClips.length > 0) {
        setActiveClipIndex(0);
      } else {
        setActiveClipIndex(undefined);
      }

      setSelectedClips([]);
      setIsRemoveAllOpen(false);
    }, [autoResizeLoading, magicWandLoading, selectedClips, setUndo, workingClips]);

    const handleRemoveAdPods = React.useCallback(() => {
      if (autoResizeLoading || magicWandLoading) {
        return;
      }

      const newWorkingClips = workingClips.map((source, i) => ({
        ...source,
        ...(selectedClips.some(sc => sc === i) && {
          adPods: [],
        }),
      }));

      setUndo(newWorkingClips);
      setWorkingClips(newWorkingClips);

      setIsRemoveAllOpen(false);
    }, [autoResizeLoading, magicWandLoading, selectedClips, setUndo, workingClips]);

    React.useEffect(() => {
      if (!activeClip) {
        return;
      }

      setInPoint(msToTimecode((activeClip.inPoint || 0) * 1000, activeClip.meta.clip.framerate, false));
      setOutPoint(
        msToTimecode(
          (activeClip.outPoint || activeClip.meta.clip.duration) * 1000,
          activeClip.meta.clip.framerate,
          false,
        ),
      );
    }, [activeClip]);

    const debouncedLevel = React.useMemo(() => debounce(val => setZoomLevel(val), 500), []);

    const allowToAddClip = React.useMemo((): boolean => {
      if (workingClips.length === 1 && workingClips[0].meta.clip.liveBroadcast) {
        return false;
      }

      return true;
    }, [workingClips]);

    const updatePlayerProgress = React.useCallback(async () => {
      if (playerRef.current) {
        const [timestamp, activeId] = await playerRef.current?.getCurrentTimeline();
        setMarkerOffset(await calcMarkerOffset(workingClips, activeId, timestamp, ZOOM_LEVELS[zoomLevel].remPerMinute));
      } else {
        setMarkerOffset('0rem');
      }
    }, [workingClips, zoomLevel]);

    React.useEffect(() => {
      const timelineInterval = setInterval(() => updatePlayerProgress(), 2500);

      updatePlayerProgress();

      return () => {
        clearInterval(timelineInterval);
      };
    }, [updatePlayerProgress]);

    React.useEffect(() => {
      inOutChanged();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inPoint, outPoint]);

    // Reset selected ad pods when active clip changes
    React.useEffect(() => {
      setSelectedAdPods([]);
    }, [activeClip, activeClipIndex]);

    const canPrev = React.useMemo(() => {
      if (activeClipIndex === 0) {
        return false;
      }

      return true;
    }, [activeClipIndex]);

    const canNext = React.useMemo(() => {
      if (activeClipIndex === workingClips.length - 1) {
        return false;
      }

      return true;
    }, [activeClipIndex, workingClips.length]);

    const updateLiveBroadcastClip = async (sources: IEpisodeSource[]) => {
      const transformedClips = await transformClips(sources);
      const transformedClipsActive = await setActive(transformedClips, 0);

      setWorkingClips(transformedClipsActive);
      setActiveClipIndex(0);
    };

    const inOutChanged = () => {
      if (!activeClip || !allowToAddClip || !hasModifiedInOutPoint) {
        return;
      }

      setInPointError(undefined);
      setOutPointError(undefined);

      if (!inPoint || inPoint.length < 11) {
        setInPointError('In Point is invalid');
        return;
      }

      if (!outPoint || outPoint.length < 11) {
        setOutPointError('Out Point is invalid');
        return;
      }

      const inPointTime = timecodeToMs(inPoint, activeClip.meta.clip.framerate);
      const outPointTime = timecodeToMs(outPoint, activeClip.meta.clip.framerate);

      let hasInOutError = false;

      if (inPointTime >= outPointTime) {
        setInPointError('In Point must be before Out Point');
        hasInOutError = true;
      }

      const realDuration = activeClip.meta.clip.duration || 0;

      if (inPointTime > realDuration * 1000) {
        const inPointCalculated = msToTimecode(inPointTime, activeClip.meta.clip.framerate, false);
        const exceededTime = msToTimecode(inPointTime - realDuration * 1000, activeClip.meta.clip.framerate, false);

        if (exceededTime !== '00:00:00:00') {
          setInPointError(
            `The calculated inPoint value of ${inPointCalculated} is greater than the total clip duration by ${exceededTime}. InPoint must be less than the total clip duration.`,
          );
          hasInOutError = true;
        }
      }

      if (outPointTime > realDuration * 1000) {
        const outPointCalculated = msToTimecode(outPointTime, activeClip.meta.clip.framerate, false);
        const exceededTime = msToTimecode(outPointTime - realDuration * 1000, activeClip.meta.clip.framerate, false);

        if (exceededTime !== '00:00:00:00') {
          setOutPointError(
            `The calculated outPoint value of ${outPointCalculated} is greater than the total clip duration by ${exceededTime}. OutPoint must be equal or less than the total clip duration.`,
          );
          hasInOutError = true;
        }
      }

      // Check to see if the timecode match even if the time doesn't
      if (
        msToTimecode(inPointTime, activeClip.meta.clip.framerate, false) ===
          msToTimecode((activeClip.inPoint || 0) * 1000, activeClip.meta.clip.framerate, false) &&
        msToTimecode(outPointTime, activeClip.meta.clip.framerate, false) ===
          msToTimecode((activeClip.outPoint || 0) * 1000, activeClip.meta.clip.framerate, false)
      ) {
        return;
      }

      if (setHasInOutPointWarning) {
        setHasInOutPointWarning(hasInOutError);
      }

      if (!hasInOutError) {
        const newWorkingClips = workingClips.map((source, index) => ({
          ...source,
          ...(index === activeClipIndex && {
            inPoint: inPointTime / 1000,
            outPoint: outPointTime / 1000,
            ...(!hasInOutError && {
              // Filter out ad pods that fall outside of the in/out points
              adPods: (source.adPods || []).filter(
                adPod => adPod.startAt * 1000 >= inPointTime && adPod.startAt * 1000 <= outPointTime,
              ),
            }),
          }),
        }));

        setUndo(newWorkingClips);
        setWorkingClips(newWorkingClips);
      }
    };

    const workingClipsChanged = () => {
      if (!isValEmpty(model) && !isValEmpty(pristineModel)) {
        let episodeDuration = 0;

        const updatedSources = workingClips.map(source => {
          episodeDuration += Number.isFinite(source.outPoint)
            ? source.outPoint! - (source.inPoint || 0)
            : source.meta.duration;

          return {
            ...source.meta,
            inPoint: source.inPoint,
            outPoint: source.outPoint,
            adPods:
              (source.adPods || []).length > 0
                ? (source.adPods || []).map(pod => {
                    episodeDuration += pod.duration;

                    return {
                      ...pod,
                      startAt: Math.ceil(pod.startAt * 1000),
                      duration: pod.duration * 1000,
                      podType: 'ad',
                    };
                  })
                : null,
          };
        });

        setFields({
          duration: workingClips.length ? Math.ceil(episodeDuration) : model.duration,
          sources: updatedSources.length ? updatedSources : undefined,
          content: updatedSources.map(source => ({
            ...source,
            adPods: null,
          })),
        });
      }
    };

    const onEntryClick = React.useCallback(
      async (index?: number, resetPlayState = true) => {
        try {
          const updatedClips = await setActive(workingClips, index);

          setActiveClipIndex(index);
          setWorkingClips(updatedClips);

          if (resetPlayState) {
            playerRef.current?.resetPlayState();
          }
        } catch (e) {
          setActiveClipIndex(undefined);
        }
      },
      [workingClips],
    );

    const onDrop = React.useCallback(
      async (from: string, to: string) => {
        const newWorkingClips = await onDropClip(workingClips, from, to);

        setUndo(newWorkingClips);
        setWorkingClips(newWorkingClips);
      },
      [workingClips, setUndo],
    );

    const totalClipAdPodDuration = React.useMemo((): string => {
      if (!activeClip) {
        return '';
      }

      return secondsToHms((activeClip.adPods || []).reduce((accumulator, pod) => pod.duration + accumulator, 0));
    }, [activeClip]);

    const clipUsesDropFrames = React.useMemo((): boolean => isDropFrame(activeClip?.meta.clip.framerate), [activeClip]);

    const totalAdPodDuration = React.useMemo((): string => {
      if (!workingClips || workingClips.length === 0) {
        return '00:00:00';
      }

      return secondsToHms(
        workingClips.reduce(
          (accumulator, source) => (source.adPods || []).reduce((acc, pod) => pod.duration + acc, 0) + accumulator,
          0,
        ),
      );
    }, [workingClips]);

    const totalDurationSeconds = React.useMemo(
      (): number =>
        workingClips && workingClips.length > 0
          ? Math.ceil(
              workingClips.reduce(
                (accumulator, source) =>
                  (source.adPods || []).reduce((acc, pod) => pod.duration + acc, 0) +
                  accumulator +
                  (source.outPoint && Number.isFinite(source.outPoint)
                    ? source.outPoint - (source.inPoint || 0)
                    : source.meta.clip.duration),
                0,
              ),
            )
          : 0,
      [workingClips],
    );

    const totalDuration = React.useMemo((): string => {
      if (totalDurationSeconds === 0) {
        return '00:00:00';
      }

      return secondsToHms(totalDurationSeconds);
    }, [totalDurationSeconds]);

    const totalEpisodeAllotment = React.useMemo((): string => {
      if (!model) {
        return '';
      }

      return secondsToHms(model.allotment || 0);
    }, [model]);

    const entryTemplate = React.useCallback(
      (entry: ISimpleEntry) => (
        <Stack space='xxxxsmall'>
          {entry.author && (
            <Heading level='h6' renderAs='tinyRegular' color='secondary'>
              {entry.author}
            </Heading>
          )}
          <Cluster align='baseline' space='xxsmall' wrap={false}>
            <Heading level='h5' renderAs='tiny' color='secondary'>
              Ad Pods:
            </Heading>
            <Heading level='h6' renderAs='tiny' color='secondary'>
              {entry.adPods?.length || '0'}
            </Heading>
          </Cluster>
          <Cluster align='baseline' space='xxsmall' wrap={false}>
            <Heading level='h5' renderAs='tiny' color='secondary'>
              Duration:
            </Heading>
            <Heading level='h6' renderAs='tinyRegular' color='secondary'>
              {msToTimecode(entry.duration * 1000, entry.meta.clip.framerate, false)}
            </Heading>
          </Cluster>
          {(Number.isFinite(entry.inPoint) || Number.isFinite(entry.outPoint)) && (
            <Cluster space='xxlarge'>
              {entry.inPoint?.toString().length || 0 > 0 ? (
                <Cluster align='baseline' space='xxsmall' wrap={false}>
                  <Heading level='h5' renderAs='tiny' color='secondary'>
                    In:
                  </Heading>
                  <Heading level='h6' renderAs='tinyRegular' color='secondary'>
                    {msToTimecode((entry.inPoint || 0) * 1000, entry.meta.clip.framerate, false)}
                  </Heading>
                </Cluster>
              ) : (
                <></>
              )}
              {Number.isFinite(entry.outPoint) ? (
                <Cluster align='baseline' space='xxsmall' wrap={false}>
                  <Heading level='h5' renderAs='tiny' color='secondary'>
                    Out:
                  </Heading>
                  <Heading level='h6' renderAs='tinyRegular' color='secondary'>
                    {msToTimecode(entry.outPoint! * 1000, entry.meta.clip.framerate, false)}
                  </Heading>
                </Cluster>
              ) : (
                <></>
              )}
            </Cluster>
          )}
          <Cluster align='baseline' space='xxsmall' wrap={false}>
            <Heading level='h5' renderAs='tiny' color='secondary'>
              Created:
            </Heading>
            <Heading level='h6' renderAs='tinyRegular' color='secondary'>
              {entry?.createdAt && new Date(entry.createdAt).toLocaleDateString()}
            </Heading>
          </Cluster>
        </Stack>
      ),
      [],
    );

    const isAdPodValid = (startAt: string): string | undefined => {
      if (!startAt || startAt.length < 11) {
        return undefined;
      }

      if (inPoint) {
        if (
          timecodeToMs(inPoint, activeClip?.meta.clip.framerate) >
          timecodeToMs(startAt, activeClip?.meta.clip.framerate)
        ) {
          return 'Ad Pod must start after In Point';
        }
      }

      if (outPoint) {
        if (
          timecodeToMs(outPoint, activeClip?.meta.clip.framerate) <
          timecodeToMs(startAt, activeClip?.meta.clip.framerate)
        ) {
          return 'Ad Pod must start before Out Point';
        }
      }
    };

    const autoResizeAdPods = async () => {
      if (autoResizeLoading || magicWandLoading || selectedClips.length > 0) {
        return;
      }

      setAutoResizeLoading(true);

      try {
        const data = await summon.put<any, IEpisode>(`episodes/${pristineModel.id}/setAdPodDurations`, {
          episode: mapPopulatedEpisodeToEpisode(model),
        });

        const clonedSources = cloneDeep(workingClips);
        const resSources = data.sources || [];

        if (clonedSources.length !== resSources.length) {
          throw new Error('Data came back differently');
        }

        for (let a = 0; a < resSources.length; a++) {
          clonedSources[a].adPods = (clonedSources[a].adPods || []).map(pod => {
            const resPod = (resSources[a].adPods || []).find(resPod => resPod.startAt / 1000 === pod.startAt);

            return {
              ...pod,
              ...(resPod && {
                duration: resPod.duration / 1000,
              }),
            };
          });
        }

        setUndo(clonedSources);
        setWorkingClips(clonedSources);
      } catch (e) {
        Toast.error('Unable to auto resize ad pods. Please try again');
      }

      setAutoResizeLoading(false);
    };

    const autoFill = async () => {
      if (autoResizeLoading || magicWandLoading || selectedClips.length > 0) {
        return;
      }

      setMagicWandLoading(true);

      try {
        const episodeId = pristineModel.id || pristineModel._id;

        const sources = (model.sources || []).map(source => ({
          ...source,
          inPoint: Math.ceil(source.inPoint),
          outPoint: Math.ceil(source.outPoint),
          clip: {
            ...source.clip,
            id: source.clip._id || source.clip.id || '',
            inPoint: source.inPoint,
            outPoint: source.outPoint,
          },
        }));

        const data = await summon.post<{episode: IMagicWand}, IClip[]>(`episodes/${episodeId}/autofill`, {
          episode: {
            ...model,
            content: sources,
            sources,
            id: episodeId,
            _id: episodeId,
            adPodsDuration: workingClips.reduce(
              (accumulator, source) => (source.adPods || []).reduce((acc, pod) => pod.duration + acc, 0) + accumulator,
              0,
            ),
          },
        });

        let newWorkingClips: ISimpleEntry[] = [];

        for (const source of data || []) {
          const foundClip =
            workingClips.find(a => a.id === source._id) || newWorkingClips.find(a => a.id === source._id);

          if (foundClip) {
            const updatedClip = cloneDeep(foundClip);

            updatedClip.inPoint = Math.ceil(source.inPoint);
            updatedClip.outPoint = !source.liveBroadcast ? Math.ceil(source.outPoint) : source.duration;
            updatedClip.duration = !source.liveBroadcast
              ? Math.ceil(
                  Number.isFinite(source.inPoint) && Number.isFinite(source.outPoint)
                    ? source.outPoint - source.inPoint
                    : source.duration,
                )
              : source.duration;

            newWorkingClips.push(updatedClip);
          } else {
            const transformed = await transformClipToEntry(source);
            newWorkingClips.push(transformed);
          }
        }

        const findActiveIndex = Math.max(
          newWorkingClips.findIndex(a => a.active),
          0,
        );
        newWorkingClips = await setActive(newWorkingClips, findActiveIndex);

        setUndo(newWorkingClips);
        setWorkingClips(newWorkingClips);
        setActiveClipIndex(findActiveIndex);
        setHighlightNewRowsIndex(newWorkingClips.map((a, i) => i));
      } catch (e) {
        Toast.error('Unable to auto fill timeline. Please try again');
      }

      setMagicWandLoading(false);
    };

    const getPinnedTimestamp = () => {
      const timestamp = playerRef.current?.getCurrentTime()[0] || 0;
      return msToTimecode(timestamp * 1000, activeClip?.meta.clip.framerate, false);
    };

    const handleAdPodClick = (icon: TIcons, index: number) => {
      if (icon === 'delete') {
        const cloned = cloneDeep(activeClip?.adPods);

        if (!cloned) {
          return;
        }

        if (Number.isFinite(activeClipIndex)) {
          dirtySourcesRef.current.add(workingClips[activeClipIndex!].id);
        }

        cloned.splice(index, 1);

        const newWorkingClips = workingClips.map((source, i) => ({
          ...source,
          ...(i === activeClipIndex && {
            adPods: cloned,
          }),
        }));

        setUndo(newWorkingClips);
        setWorkingClips(newWorkingClips);
      } else if (icon === 'edit') {
        setAdPodsPopoverOpen({[index]: true});
      }
    };

    const handleAdPodUpdate = (adPod: IAdPodValue, index = -1) => {
      const cloned = cloneDeep(activeClip?.adPods || []);

      if (index >= 0) {
        if (!cloned[index]) {
          return;
        }

        cloned[index].duration = adPod.duration;

        if (
          msToTimecode(cloned[index].startAt * 1000, activeClip?.meta.clip.framerate, false) !==
          msToTimecode(adPod.startAt * 1000, activeClip?.meta.clip.framerate, false)
        ) {
          cloned[index].startAt = adPod.startAt;
        }
      } else {
        cloned.push({
          duration: adPod.duration,
          startAt: adPod.startAt,
        });
      }

      const uniqPods = uniqBy(cloned, (pod: IAdPodValue) => pod.startAt);

      // There is a duplicate of startAt somewhere
      if (uniqPods.length !== cloned.length) {
        Toast.error('Start times need to be unique. Please try again');
        return;
      }

      if (Number.isFinite(activeClipIndex)) {
        dirtySourcesRef.current.add(workingClips[activeClipIndex!].id);
      }

      const newWorkingClips = workingClips.map((source, i) => ({
        ...source,
        ...(i === activeClipIndex && {
          adPods: cloned.sort((a, b) => a.startAt - b.startAt),
        }),
      }));

      setUndo(newWorkingClips);
      setWorkingClips(newWorkingClips);

      setAdPodsPopoverOpen({});
    };

    const rowStatus = React.useCallback((row: any) => row.state, []);

    return (
      <Box
        background='pewter'
        borderTop={true}
        borderSize='0.125rem'
        borderColor='cavern'
        fullHeight={true}
        ref={boxRef}
      >
        <style>{css}</style>
        <Cluster fullHeight={true}>
          <Box
            paddingTop={{mobile: 'xsmall', wide: 'small'}}
            borderRight={true}
            borderSize='0.25rem'
            borderColor='shadow'
            fullHeight={true}
            overflow='auto'
            width='60%'
            widthAtTabletAndDown='50%'
            widthAtMobile='100%'
          >
            <Cover gutterTop='medium' scrolling={true} coverId='timelineContainer'>
              <Template label='header'>
                <Box paddingX={{mobile: 'medium', wide: 'xlarge'}}>
                  <Cluster justify='space-between' space='medium' align='center'>
                    <Cluster align='center' space='medium'>
                      <Heading level='h3' color='secondary'>
                        Clips
                      </Heading>
                      <Cluster space='small'>
                        <Icon
                          id='btnTimelineView'
                          icon='table'
                          size='large'
                          color={clipView === 'table' ? 'primary' : 'dust'}
                          onClick={() => setClipView('table')}
                        />
                        <Icon
                          id='btnListView'
                          icon='playlist'
                          size='large'
                          color={clipView === 'timeline' ? 'primary' : 'dust'}
                          onClick={() => {
                            setClipView('timeline');
                            setSelectedClips([]);
                          }}
                        />
                      </Cluster>
                    </Cluster>
                    <Cluster space='medium' align='center'>
                      {clipView === 'timeline' && (
                        <Range size='xsmall' min={0} max={6} onChange={debouncedLevel} value={zoomLevel} />
                      )}
                      {CAN_EDIT && (
                        <Popover trigger='mouseenter' appendToBody={true}>
                          <Template label='trigger'>
                            <Icon
                              id='btnShuffle'
                              icon='shuffle'
                              disabled={selectedClips.length > 0}
                              color={selectedClips.length > 0 ? 'dust' : undefined}
                              onClick={() => {
                                if (autoResizeLoading || magicWandLoading || selectedClips.length > 0) {
                                  return;
                                }

                                const shuffledClips = shuffle(workingClips);
                                const foundActive = Math.max(
                                  0,
                                  shuffledClips.findIndex(c => c.active),
                                );

                                setUndo(shuffledClips);
                                setWorkingClips(shuffledClips);
                                setActiveClipIndex(foundActive);
                              }}
                            />
                          </Template>
                          <Template label='popover'>Shuffle</Template>
                        </Popover>
                      )}
                      {CAN_EDIT && (
                        <Popover trigger='mouseenter' appendToBody={true}>
                          <Template label='trigger'>
                            {magicWandLoading ? (
                              <Spinner size='small' />
                            ) : (
                              <Icon
                                id='btnMagicWand'
                                icon='autofix'
                                size='large'
                                disabled={selectedClips.length > 0}
                                onClick={autoFill}
                              />
                            )}
                          </Template>
                          <Template label='popover'>Auto Fill</Template>
                        </Popover>
                      )}
                      {CAN_EDIT && (
                        <Popover trigger='mouseenter' appendToBody={true}>
                          <Template label='trigger'>
                            {autoResizeLoading ? (
                              <Spinner size='small' />
                            ) : (
                              <Icon
                                id='btnResize'
                                icon='arrowleftright'
                                size='large'
                                disabled={selectedClips.length > 0}
                                onClick={autoResizeAdPods}
                              />
                            )}
                          </Template>
                          <Template label='popover'>Auto Resize Ad Pods</Template>
                        </Popover>
                      )}
                      {CAN_EDIT && (
                        <Popover
                          manualTrigger={true}
                          visible={isRemoveAllOpen}
                          onClickOutside={() => setIsRemoveAllOpen(false)}
                        >
                          <Template label='trigger'>
                            <Icon
                              id='btnRemove'
                              icon='deleteall'
                              hoverColor='delete'
                              disabled={selectedClips.length < 1}
                              onClick={() =>
                                clipView === 'table' &&
                                selectedClips.length > 0 &&
                                setIsRemoveAllOpen(isRemoveAllOpen => !isRemoveAllOpen)
                              }
                            />
                          </Template>
                          <Template label='popover'>
                            <Box
                              id='removeClipsPopover'
                              padding={popoverActionsProps.padding}
                              background={popoverActionsProps.background}
                            >
                              <Stack space='small'>
                                <Icon
                                  id='removeSelectedClips'
                                  icon='delete'
                                  textColor='delete'
                                  color='delete'
                                  hoverColor='deleteLight'
                                  space='xxsmall'
                                  onClick={handleRemoveClips}
                                >
                                  Remove Selected Clips
                                </Icon>
                                <Icon
                                  id='removeSelectedAdPods'
                                  icon='delete'
                                  textColor='delete'
                                  color='delete'
                                  hoverColor='deleteLight'
                                  space='xxsmall'
                                  onClick={handleRemoveAdPods}
                                >
                                  Remove Ad Pods From Selected Clips
                                </Icon>
                                <Cluster justify='space-between'>
                                  <div></div>
                                  <Cluster>
                                    <Button id='btnCancelRemove' ghost={true} onClick={() => setIsRemoveAllOpen(false)}>
                                      Cancel
                                    </Button>
                                  </Cluster>
                                </Cluster>
                              </Stack>
                            </Box>
                          </Template>
                        </Popover>
                      )}
                      <Button
                        id='btnAddClip'
                        type='primary'
                        permission={editPermission}
                        onClick={() => setIsAddClipOpen(true)}
                        state={allowToAddClip ? '' : 'disabled'}
                      >
                        + Add Clip
                      </Button>
                      <Dialog isOpen={isAddClipOpen} onClose={() => setIsAddClipOpen(false)} width='100%' height='100%'>
                        <Template label='header'>
                          <Heading level='h2'>Add Clip(s)</Heading>
                        </Template>
                        <Template label='body'>
                          <ClipList
                            actionsCol={false}
                            addNewClips={false}
                            checkboxCol='multiple'
                            presetSearch={{
                              activeRegion: model.activeRegion ? [model.activeRegion] : undefined,
                              published: true,
                            }}
                            inModal
                            isSearchExpanded
                            onSelect={val => setSelectedAddClip(val as IClip[])}
                            existingClipsId={workingClips?.map(clip => clip.id) || []}
                            trackClipsSortList={true}
                            totalDuration={totalDuration}
                            totalAllotment={totalEpisodeAllotment}
                          />
                        </Template>
                        <Template label='footer'>
                          <Cluster justify='space-between'>
                            <div></div>
                            <Cluster space='small'>
                              <Button
                                ghost={true}
                                size='small'
                                onClick={() => {
                                  setIsAddClipOpen(false);
                                  setSelectedAddClip(undefined);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                type='primary'
                                size='small'
                                state={!selectedAddClip ? 'disabled' : ''}
                                onClick={async () => {
                                  if (!selectedAddClip || selectedAddClip.length === 0) {
                                    return;
                                  }

                                  const newClips: ISimpleEntry[] = [];
                                  let newClipError: IClip | undefined;
                                  let clipError =
                                    'is not ready to be added to episode. Please select a different clip.';

                                  const liveBroadcastClips = selectedAddClip.filter(clip => clip.liveBroadcast);

                                  if (liveBroadcastClips.length > 1) {
                                    Toast.error('You cannot add more than 1 live broadcast clip to an episode.');
                                    return;
                                  }

                                  if (liveBroadcastClips.length > 0 && selectedAddClip.length >= 2) {
                                    Toast.error(
                                      'You cannot add a live broadcast clip and a VOD clip to the same episode.',
                                    );
                                    return;
                                  }

                                  for (const clip of selectedAddClip) {
                                    if (!Number.isFinite(clip.duration) || clip.duration === 0) {
                                      newClipError = clip;
                                      break;
                                    }

                                    if (clip.outPoint === 0 && !clip.liveBroadcast) {
                                      newClipError = clip;
                                      break;
                                    }

                                    if (!clip.published) {
                                      newClipError = clip;
                                      clipError =
                                        'cannot be added to the episode because the clip is not published. Please update, or select a different clip.';
                                      break;
                                    }

                                    if (clip.liveBroadcast && workingClips.length > 0) {
                                      newClipError = clip;
                                      clipError =
                                        'please remove existing clips in order to add a liveBroadcast clip to this episode.';
                                      break;
                                    }

                                    newClips.push(
                                      await transformClipToEntry(
                                        {
                                          ...clip,
                                          ...(clip.liveBroadcast && {
                                            outPoint: (clip.inPoint || 0) + model.allotment!,
                                          }),
                                        },
                                        true,
                                      ),
                                    );
                                  }

                                  if (newClipError) {
                                    Toast.error(`${newClipError.name} ${clipError}`);

                                    return;
                                  }

                                  const newActiveIndex = workingClips.length;
                                  const updatedClips = await setActive([...workingClips, ...newClips], newActiveIndex);
                                  setHighlightNewRowsIndex(newClips.map((nc, i) => i + workingClips.length));

                                  setUndo(updatedClips);
                                  setWorkingClips(updatedClips);
                                  setActiveClipIndex(newActiveIndex);
                                  setIsAddClipOpen(false);
                                  setSelectedAddClip(undefined);
                                }}
                              >
                                Add Clip
                              </Button>
                            </Cluster>
                          </Cluster>
                        </Template>
                      </Dialog>
                    </Cluster>
                  </Cluster>
                </Box>
              </Template>
              <Template label='cover'>
                <Box
                  fullHeight={true}
                  overflow='auto'
                  paddingLeft={{mobile: 'medium', wide: 'xlarge'}}
                  paddingRight={{mobile: 'medium', wide: 'none'}}
                >
                  {clipView === 'timeline' && (
                    <Timeline
                      id='clipsTimeline'
                      type='timeline'
                      zoomLevel={zoomLevel}
                      entries={workingClips}
                      onEntryClick={onEntryClick}
                      onDrop={onDrop}
                      transformEntry={entryTemplate}
                      markerOffset={markerOffset}
                    />
                  )}
                  {clipView === 'table' && (
                    <Box
                      id='tableClips'
                      overflow='auto'
                      fullHeight={true}
                      borderTop={true}
                      borderColor='mist'
                      borderSize='0.0625rem'
                    >
                      <Cluster growNthChild={2} space='medium' wrap={false}>
                        {timeRowsLoading ? (
                          <Box minWidth='80.55px'>
                            <Spinner center={true} size='small' minHeight='100px' />
                          </Box>
                        ) : timeRows.length ? (
                          <Box background='graphite' minWidth='80.55px'>
                            <Table
                              id='tableStartTime'
                              size='small'
                              stickyHeader={true}
                              headerBackgroundColor='graphite'
                              lightDividers={true}
                              cols={[
                                {
                                  label: 'Start Time',
                                  transform: row => <div style={{height: row.height}}>{row.timestamp}</div>,
                                },
                              ]}
                              rows={timeRows}
                            ></Table>
                          </Box>
                        ) : (
                          <Box minWidth='80.55px'></Box>
                        )}
                        <Table
                          id='tableClipItems'
                          size='small'
                          wrapContent={true}
                          stickyHeader={true}
                          draggable={true}
                          dragKey='clip-list'
                          dropKeys={['clip-list']}
                          emptyMsg='No clips on this episode'
                          selectable='multiple'
                          highlightable={true}
                          highlightedIndex={activeClipIndex}
                          onHighlight={(row, index) => onEntryClick(index)}
                          rowStatus={rowStatus}
                          onSort={colName => changeSort(colName as keyof typeof TABLE_COLUMN_NAME)}
                          sortDir={sortDir}
                          sortCol={sortCol}
                          cols={[
                            {
                              label: '',
                              zeroRightPadding: true,
                              colWidth: '1.25rem',
                              transform: row => {
                                if (!row?.errorMessagesWithState?.length) {
                                  return null;
                                }

                                let state = 'warning';

                                row.errorMessagesWithState.forEach(a => {
                                  if (a.state === 'error') {
                                    state = 'error';
                                  }
                                });

                                return (
                                  <Popover trigger='mouseenter'>
                                    <Template label='trigger'>
                                      <Icon icon='warning' verticalAlign='text-bottom' color={state as any} />
                                    </Template>
                                    <Template label='popover'>
                                      <Box
                                        background={popoverActionsProps.background}
                                        width={popoverActionsProps.widthWide}
                                        paddingX='small'
                                        paddingY='xxsmall'
                                      >
                                        <Stack space='xxxsmall'>
                                          {row.errorMessagesWithState.map((validatorMessage, index) => (
                                            <div key={index}>
                                              <Icon
                                                icon='warning'
                                                iconAlign='baseline'
                                                space='small'
                                                color={validatorMessage.state as any}
                                              >
                                                {validatorMessage.message}
                                              </Icon>
                                            </div>
                                          ))}
                                        </Stack>
                                      </Box>
                                    </Template>
                                  </Popover>
                                );
                              },
                            },
                            {
                              label: 'Name',
                              sortable: true,
                              transform: row => (
                                <Stack space='xxxsmall'>
                                  <TdMultilineTruncate
                                    text={row!.title}
                                    title={row!.title}
                                    row={row}
                                    url={getPrefixedUrl(contentRoutes.paths.clipEditDetailsPage.replace(':id', row.id))}
                                    target='_blank'
                                    rel='opener'
                                  />
                                  <Cluster space='xxsmall'>
                                    <Paragraph size='xsmall' color='secondary'>
                                      In Point:{' '}
                                      {msToTimecode((row!.inPoint || 0) * 1000, row!.meta.clip.framerate, false)}{' '}
                                    </Paragraph>
                                    <Divider direction='vertical' flexChild={true} color='dust' />
                                    <Paragraph size='xsmall' color='secondary'>
                                      Out Point: {msToTimecode(row!.outPoint! * 1000, row!.meta.clip.framerate, false)}
                                    </Paragraph>
                                  </Cluster>
                                </Stack>
                              ),
                              colMinWidth: '15.625rem',
                            },
                            {
                              label: 'Author',
                              sortable: true,
                              transform: (row, col, index) => (
                                <TdMultilineTruncate
                                  onClick={() => onEntryClick(index)}
                                  allowClickOnEmptySpace={true}
                                  text={row!.author}
                                  title={row!.author}
                                  color='inherit'
                                  row={row}
                                />
                              ),
                              colMinWidth: '10.9375rem',
                            },
                            {
                              label: 'Created At',
                              sortable: true,
                              transform: row => row!.createdAt && new Date(row!.createdAt).toLocaleDateString(),
                              colWidth: '6.3125rem',
                              colMinWidth: '6.3125rem',
                            },
                            {
                              label: 'Duration',
                              transform: row => msToTimecode(row!.duration * 1000, row!.meta.clip.framerate, false),
                              colWidth: '5.625rem',
                            },
                            {
                              label: 'Pods',
                              transform: row => row!.adPods?.length,
                              colWidth: '2.8125rem',
                            },
                          ]}
                          onSelect={(rows, indexes) => setSelectedClips(indexes)}
                          selectedIndex={selectedClips}
                          rows={workingClips}
                          getRowSize={true}
                          ref={tableRef}
                          onDrop={(_from, _to, fromIndexes, dropIndex) => {
                            const reorderedList = reorderList(fromIndexes, dropIndex, workingClips);
                            const newActiveIndex = reorderedList.findIndex(clip => clip.active);

                            setActiveClipIndex(newActiveIndex);
                            setSelectedClips([]);
                            setUndo(reorderedList);
                            setWorkingClips(reorderedList);
                          }}
                          highlightNew={true}
                          highlightNewRowsIndex={highlightNewRowsIndex}
                        ></Table>
                      </Cluster>
                    </Box>
                  )}
                </Box>
              </Template>
              <Template label='footer'>
                <Box
                  paddingY={{mobile: 'medium', wide: 'large'}}
                  paddingX={{mobile: 'medium', wide: 'xlarge'}}
                  borderTop={true}
                  borderSize='0.25rem'
                  borderColor='shadow'
                >
                  <Cluster justify='space-between'>
                    <div></div>
                    <Cluster space='large'>
                      <Stack space='xsmall'>
                        <Cluster space='medium' justify='space-between'>
                          <Heading level='h6' color='secondary'>
                            Total AdPod Duration
                          </Heading>
                          <Heading
                            level='h6'
                            monospace={true}
                            monospaceWeight='normal'
                            color='secondary'
                            id='totalAdPodDuration'
                          >
                            {totalAdPodDuration}
                          </Heading>
                        </Cluster>
                        <Cluster space='medium' justify='space-between'>
                          <Heading level='h5'>
                            Total
                            Duration&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </Heading>
                          <Cluster space='xxxxsmall' align='center'>
                            <Heading level='h5' monospace={true} monospaceWeight='bold' id='totalDuration'>
                              {totalDuration}
                            </Heading>
                            <Heading level='h6' monospace={true} monospaceWeight='normal' color='secondary'>
                              /
                            </Heading>
                            {CAN_EDIT ? (
                              <Popover
                                appendToBody={true}
                                manualTrigger={true}
                                visible={isEditDurationOpen}
                                onClickOutside={() => setIsEditDurationOpen(false)}
                              >
                                <Template label='trigger'>
                                  <Click
                                    onClick={() => setIsEditDurationOpen(isEditDurationOpen => !isEditDurationOpen)}
                                  >
                                    <Heading level='h6' monospace={true} monospaceWeight='normal' color='highlight'>
                                      {totalEpisodeAllotment}
                                    </Heading>
                                  </Click>
                                </Template>
                                <Template label='popover'>
                                  <Box padding='small' background='charcoal'>
                                    <Stack space='small'>
                                      <FormItem label='Episode Allotment'>
                                        <TextInput
                                          value={totalEpisodeAllotment}
                                          fixedPlaceholder='HH:MM:SS'
                                          mask='NN:[0-5]N:[0-5]N'
                                          onChange={val => setEditAllotment(val)}
                                        />
                                      </FormItem>
                                      <Cluster justify='space-between'>
                                        <div></div>
                                        <Cluster space='small'>
                                          <Button ghost={true} onClick={() => setIsEditDurationOpen(false)}>
                                            Cancel
                                          </Button>
                                          <Button
                                            state={totalDurationSeconds === model.allotment ? 'disabled' : ''}
                                            onClick={() => {
                                              setFields({
                                                allotment: totalDurationSeconds,
                                              });
                                              setIsEditDurationOpen(false);
                                            }}
                                          >
                                            Match Duration
                                          </Button>
                                          <Button
                                            type='primary'
                                            state={!editAllotment || editAllotment.length < 8 ? 'disabled' : ''}
                                            onClick={() => {
                                              if (!editAllotment || editAllotment.length < 8) {
                                                return;
                                              }

                                              const newAllotment = hmsToSeconds(editAllotment);
                                              const sourcesUpdated = cloneDeep(model.sources) || [];

                                              if (sourcesUpdated.length === 1 && sourcesUpdated[0].clip.liveBroadcast) {
                                                sourcesUpdated[0] = {
                                                  ...sourcesUpdated[0],
                                                  outPoint: (sourcesUpdated[0].inPoint || 0) + newAllotment,
                                                  clip: {
                                                    ...sourcesUpdated[0].clip,
                                                    outPoint: (sourcesUpdated[0].clip.inPoint || 0) + newAllotment,
                                                  },
                                                };
                                              }

                                              setFields({
                                                sources: sourcesUpdated,
                                                allotment: newAllotment,
                                              });

                                              setIsEditDurationOpen(false);
                                              updateLiveBroadcastClip(sourcesUpdated);
                                            }}
                                          >
                                            Edit Duration
                                          </Button>
                                        </Cluster>
                                      </Cluster>
                                    </Stack>
                                  </Box>
                                </Template>
                              </Popover>
                            ) : (
                              <Heading level='h6' monospace={true} monospaceWeight='normal' color='highlight'>
                                {totalEpisodeAllotment}
                              </Heading>
                            )}
                          </Cluster>
                        </Cluster>
                      </Stack>
                    </Cluster>
                  </Cluster>
                </Box>
              </Template>
            </Cover>
          </Box>
          {activeClip ? (
            <Box width='40%' widthAtTabletAndDown='50%' widthAtMobile='100%' overflow='auto' fullHeight={true}>
              <Cover gutterTop='medium' coverTemplateHeight='100%' minHeightCover='160px' scrolling={true}>
                <Template label='header'>
                  <Box
                    borderBottom={true}
                    borderSize='0.25rem'
                    borderColor='shadow'
                    paddingTop={{mobile: 'medium', wide: 'large'}}
                    paddingBottom={{mobile: 'medium', wide: 'xxxsmall'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    fullHeight={true}
                  >
                    <Stack space='large'>
                      <Cluster space='small' justify='space-between' align='end'>
                        <Heading level='h3' color='secondary'>
                          Clip Preview
                        </Heading>
                        <Cluster space='xlarge' align='center'>
                          <Heading level='h4'>
                            <Icon
                              linkTarget='_blank'
                              id='viewClipButton'
                              href={'/clips/' + activeClip.id}
                              icon='open'
                              color='primary'
                              hoverColor='primaryLight'
                              textColor='primary'
                              space='xxsmall'
                              textDecoration={false}
                            >
                              View Clip
                            </Icon>
                          </Heading>
                          {CAN_EDIT && (
                            <Popover
                              manualTrigger={true}
                              visible={isRemoveClipOpen}
                              onClickOutside={() => setIsRemoveClipOpen(false)}
                            >
                              <Template label='trigger'>
                                <Heading level='h4'>
                                  <Icon
                                    icon='delete'
                                    id='removeClipButton'
                                    color='delete'
                                    textColor='delete'
                                    hoverColor='deleteLight'
                                    space='xxsmall'
                                    onClick={() => setIsRemoveClipOpen(isRemoveClipOpen => !isRemoveClipOpen)}
                                  >
                                    Remove Clip
                                  </Icon>
                                </Heading>
                              </Template>
                              <Template label='popover'>
                                <DeleteConfirmation
                                  message='Are you sure you want to remove this clip?'
                                  cancelButtonFunction={() => setIsRemoveClipOpen(false)}
                                  proceedButtonFunction={async () => {
                                    setIsRemoveClipOpen(false);
                                    removeClip();
                                  }}
                                />
                              </Template>
                            </Popover>
                          )}
                        </Cluster>
                      </Cluster>
                      <Stack space='xlarge'>
                        <Stack>
                          <PlaylistPlayer
                            ref={playerRef}
                            from='meta'
                            height='15.75rem'
                            clips={workingClips}
                            id='clipPreviewContent'
                            activeIndex={activeClipIndex}
                            onActiveChanged={onEntryClick}
                            showControls={false}
                            onError={onError}
                            onLoad={onLoad}
                          />
                          <Box background='black'>
                            <Cluster justify='space-between' space='medium' align='center'>
                              <Button
                                id='prevClipButton'
                                ghost={true}
                                icon='chevronleft'
                                state={canPrev ? '' : 'disabled'}
                                onClick={() => {
                                  if (playerRef.current?.getIsPlaying()) {
                                    /** This is a stupid way to make auto-playback reliable */
                                    setTimeout(
                                      () => times(15, num => setTimeout(() => playerRef.current?.play(), num * 100)),
                                      10,
                                    );
                                  }

                                  onEntryClick(activeClipIndex! - 1, false);
                                }}
                              >
                                Prev Clip
                              </Button>
                              <Cluster space='xxlarge'>
                                <Icon
                                  id='backButton'
                                  icon='fivesback'
                                  size='large'
                                  disabled={playerError}
                                  onClick={() => videoSeek(-5)}
                                />
                                <Icon
                                  id='pinButton'
                                  icon='pushpin'
                                  disabled={playerError || !CAN_EDIT || !allowToAddClip}
                                  size='large'
                                  onClick={addPinnedAdPod}
                                />
                                <Icon
                                  id='forwardButton'
                                  icon='fivesforward'
                                  disabled={playerError}
                                  size='large'
                                  onClick={() => videoSeek(5)}
                                />
                              </Cluster>
                              <Button
                                id='nexClipButton'
                                ghost={true}
                                icon='chevronright'
                                iconPosition='right'
                                state={canNext ? '' : 'disabled'}
                                onClick={() => {
                                  if (playerRef.current?.getIsPlaying()) {
                                    /** This is a stupid way to make auto-playback reliable */
                                    setTimeout(
                                      () => times(15, num => setTimeout(() => playerRef.current?.play(), num * 100)),
                                      10,
                                    );
                                  }

                                  onEntryClick(activeClipIndex! + 1, false);
                                }}
                              >
                                Next Clip
                              </Button>
                            </Cluster>
                          </Box>
                        </Stack>
                        <Cluster align='flex-start' justify='center' space='small'>
                          <Box width='11.875rem'>
                            <FormItem
                              label='In Point'
                              permission={editPermission}
                              state={inPointError ? 'error' : activeClip.meta.clip.liveBroadcast ? 'disabled' : ''}
                              helpText={inPointError}
                            >
                              <TextInput
                                id='inPoint'
                                value={inPoint}
                                {...(CAN_EDIT && {iconRight: 'playhead'})}
                                onChange={val => {
                                  setHasModifiedInOutPoint(true);
                                  setInPoint(val);
                                }}
                                onIconRightClick={() => setInPoint(getPinnedTimestamp())}
                                fixedPlaceholder={
                                  clipUsesDropFrames
                                    ? dropFramePlaceholder.placeholder
                                    : nonDropFramePlaceholder.placeholder
                                }
                                mask={clipUsesDropFrames ? dropFramePlaceholder.mask : nonDropFramePlaceholder.mask}
                              />
                            </FormItem>
                          </Box>
                          <Box width='11.875rem'>
                            <FormItem
                              label='Out Point'
                              permission={editPermission}
                              state={outPointError ? 'error' : activeClip.meta.clip.liveBroadcast ? 'disabled' : ''}
                              helpText={outPointError}
                            >
                              <TextInput
                                id='outPoint'
                                value={outPoint}
                                {...(CAN_EDIT && {iconRight: 'playhead'})}
                                onChange={val => {
                                  setHasModifiedInOutPoint(true);
                                  setOutPoint(val);
                                }}
                                onIconRightClick={() => setOutPoint(getPinnedTimestamp())}
                                fixedPlaceholder={
                                  clipUsesDropFrames
                                    ? dropFramePlaceholder.placeholder
                                    : nonDropFramePlaceholder.placeholder
                                }
                                mask={clipUsesDropFrames ? dropFramePlaceholder.mask : nonDropFramePlaceholder.mask}
                              />
                            </FormItem>
                          </Box>
                          <Box width='145px'>
                            <FormItem child='Paragraph' label='Computed Clip Duration'>
                              <Paragraph id='compClipDuration'>
                                {msToTimecode(
                                  ((isNumber(activeClip.outPoint)
                                    ? activeClip.outPoint
                                    : isNumber(activeClip.meta.outPoint)
                                    ? activeClip.meta.outPoint
                                    : 0) -
                                    (isNumber(activeClip.inPoint)
                                      ? activeClip.inPoint
                                      : isNumber(activeClip.meta.inPoint)
                                      ? activeClip.meta.inPoint
                                      : 0)) *
                                    1000,
                                  activeClip.meta.clip.framerate,
                                  !allowToAddClip,
                                )}
                              </Paragraph>
                            </FormItem>
                          </Box>
                        </Cluster>
                      </Stack>
                    </Stack>
                  </Box>
                </Template>
                <Template label='cover'>
                  <Box
                    paddingBottom={{mobile: 'xxxxxxsmall', wide: 'xxsmall'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    fullHeight={true}
                    overflow='auto'
                  >
                    <Cover scrolling={true} minHeight='auto' stickyFooter={false} gutter='xsmall'>
                      <Template label='header'>
                        <Cluster align='center' justify='space-between' space='small'>
                          <Cluster space='large' align='flex-end'>
                            <Cluster align='center' space='small'>
                              <Heading level='h3' color='secondary'>
                                Ad Pods
                              </Heading>
                              <Popover
                                id='adPodsHelpMessage'
                                allowedPlacements={['top', 'bottom', 'bottom-start', 'top-end']}
                                trigger='mouseenter'
                              >
                                <Template label='trigger'>
                                  <Icon
                                    id='adPodsHelpTrigger'
                                    icon='info'
                                    size='medium'
                                    iconAlign='center'
                                    space='xxsmall'
                                    color='info'
                                  />
                                </Template>
                                <Template label='popover'>
                                  Ad Pods will be cleared if In/Out Points are modified.
                                </Template>
                              </Popover>
                            </Cluster>
                            <Cluster justify='space-between' align='center'>
                              <Cluster space='xxsmall' align='baseline'>
                                <Heading level='h6' color='secondary'>
                                  Total AdPod Duration:
                                </Heading>
                                <Heading
                                  id='totalAdPodsDuration'
                                  level='h6'
                                  monospace={true}
                                  monospaceWeight='normal'
                                  color='secondary'
                                >
                                  {totalClipAdPodDuration}
                                </Heading>
                              </Cluster>
                            </Cluster>
                          </Cluster>
                          <Cluster space='small'>
                            {CAN_EDIT && !activeClip.meta.clip.liveBroadcast && (
                              <Popover
                                manualTrigger={true}
                                visible={adPodsPopoverOpen.add}
                                onClickOutside={() => setAdPodsPopoverOpen({})}
                                appendToBody={true}
                              >
                                <Template label='trigger'>
                                  <Button
                                    id='addAdPodTriggerBtn'
                                    type='primary'
                                    permission={editPermission}
                                    onClick={() => setAdPodsPopoverOpen({add: true})}
                                  >
                                    + Add Ad Pod
                                  </Button>
                                </Template>
                                <Template label='popover'>
                                  <AdPodForm
                                    visible={adPodsPopoverOpen.add}
                                    onCancel={() => setAdPodsPopoverOpen({})}
                                    onPin={() => (playerRef.current ? playerRef.current.getCurrentTime()[0] * 1000 : 0)}
                                    fixedPlaceholder={
                                      clipUsesDropFrames
                                        ? dropFramePlaceholder.placeholder
                                        : nonDropFramePlaceholder.placeholder
                                    }
                                    mask={clipUsesDropFrames ? dropFramePlaceholder.mask : nonDropFramePlaceholder.mask}
                                    isValid={isAdPodValid}
                                    onSubmit={handleAdPodUpdate}
                                    framerate={activeClip.meta.clip.framerate}
                                  />
                                </Template>
                              </Popover>
                            )}
                            {CAN_EDIT && !activeClip.meta.clip.liveBroadcast && (
                              <Popover
                                manualTrigger={true}
                                visible={adPodsDeletePopoverOpen}
                                onClickOutside={() => setAdPodsDeletePopoverOpen(false)}
                                appendToBody={true}
                              >
                                <Template label='trigger'>
                                  <Button
                                    id='deleteAdPodBtn'
                                    type='delete'
                                    permission={editPermission}
                                    state={selectedAdPods.length > 0 ? '' : 'disabled'}
                                    onClick={() => setAdPodsDeletePopoverOpen(true)}
                                  >
                                    Delete
                                  </Button>
                                </Template>
                                <Template label='popover'>
                                  <DeleteConfirmation
                                    message={`Are you sure you want to delete ${selectedAdPods.length} Ad Pods?`}
                                    cancelButtonFunction={() => {
                                      setAdPodsDeletePopoverOpen(false);
                                    }}
                                    proceedButtonFunction={() => {
                                      const cloned = cloneDeep(activeClip?.adPods || []);

                                      const newWorkingClips = workingClips.map((source, i) => ({
                                        ...source,
                                        ...(i === activeClipIndex && {
                                          adPods: cloned.filter(
                                            a => !selectedAdPods.some(b => a.startAt === b.startAt),
                                          ),
                                        }),
                                      }));

                                      setUndo(newWorkingClips);
                                      setWorkingClips(newWorkingClips);

                                      setAdPodsDeletePopoverOpen(false);
                                      setSelectedAdPods([]);
                                    }}
                                  />
                                </Template>
                              </Popover>
                            )}
                          </Cluster>
                        </Cluster>
                      </Template>
                      <Template label='cover'>
                        {
                          <Table
                            id='adPodsTable'
                            fixedHeader={true}
                            selectable='multiple'
                            size='small'
                            cols={[
                              {
                                label: 'Time Stamp',
                                transform: row => (
                                  <Click
                                    color='primary'
                                    size='small'
                                    hoverColor='primaryLight'
                                    onClick={ev => {
                                      ev.preventDefault();
                                      ev.stopPropagation();

                                      playerRef.current?.seekTo(row.startAt);
                                    }}
                                  >
                                    {msToTimecode(row.startAt * 1000, activeClip.meta.clip.framerate, false)}
                                  </Click>
                                ),
                              },
                              {
                                label: 'Duration',
                                transform: row => `${row.duration}s`,
                              },
                              ...(CAN_EDIT && !activeClip.meta.clip.liveBroadcast
                                ? [
                                    {
                                      label: 'Actions',
                                      colWidth: '6.25rem',
                                      transform: (row, _col, index) => (
                                        <TableActions
                                          row={row}
                                          icons={[]}
                                          deleteOption={true}
                                          altTitle='Ad Pod'
                                          onClick={(_row, icon) => handleAdPodClick(icon, index)}
                                        >
                                          <Popover
                                            appendToBody={true}
                                            manualTrigger={true}
                                            visible={adPodsPopoverOpen[index]}
                                            onClickOutside={() => setAdPodsPopoverOpen({})}
                                          >
                                            <Template label='trigger'>
                                              <Icon
                                                id='adPodEditAction'
                                                space='small'
                                                icon='edit'
                                                onClick={() => handleAdPodClick('edit', index)}
                                              />
                                            </Template>
                                            <Template label='popover'>
                                              <AdPodForm
                                                value={{
                                                  duration: row.duration,
                                                  startAt: row.startAt,
                                                }}
                                                visible={adPodsPopoverOpen[index]}
                                                onCancel={() => setAdPodsPopoverOpen({})}
                                                onPin={() =>
                                                  playerRef.current ? playerRef.current.getCurrentTime()[0] * 1000 : 0
                                                }
                                                fixedPlaceholder={
                                                  clipUsesDropFrames
                                                    ? dropFramePlaceholder.placeholder
                                                    : nonDropFramePlaceholder.placeholder
                                                }
                                                mask={
                                                  clipUsesDropFrames
                                                    ? dropFramePlaceholder.mask
                                                    : nonDropFramePlaceholder.mask
                                                }
                                                isValid={isAdPodValid}
                                                onSubmit={podData => handleAdPodUpdate(podData, index)}
                                                framerate={activeClip.meta.clip.framerate}
                                              />
                                            </Template>
                                          </Popover>
                                          <Icon
                                            id='adPodPinAction'
                                            space='small'
                                            icon='pushpin'
                                            disabled={playerError}
                                            onClick={() => {
                                              if (!CAN_EDIT || !Number.isFinite(activeClipIndex)) {
                                                return;
                                              }

                                              const cloned = cloneDeep(activeClip?.adPods);

                                              if (!cloned || !cloned[index]) {
                                                return;
                                              }

                                              cloned[index].startAt = playerRef.current
                                                ? playerRef.current.getCurrentTime()[0]
                                                : 0;

                                              const uniqPods = uniqBy(cloned, (pod: IAdPodValue) => pod.startAt);

                                              // There is a duplicate of startAt somewhere
                                              if (uniqPods.length !== cloned.length) {
                                                Toast.error('Start times need to be unique. Please try again');
                                                return;
                                              }

                                              if (inPoint) {
                                                if (
                                                  timecodeToMs(inPoint, activeClip?.meta.clip.framerate) >
                                                  cloned[index].startAt * 1000
                                                ) {
                                                  Toast.error('Ad Pod must start after In Point');
                                                  return;
                                                }
                                              }

                                              if (outPoint) {
                                                if (
                                                  timecodeToMs(outPoint, activeClip?.meta.clip.framerate) <
                                                  cloned[index].startAt * 1000
                                                ) {
                                                  Toast.error('Ad Pod must start before Out Point');
                                                  return;
                                                }
                                              }

                                              dirtySourcesRef.current.add(workingClips[activeClipIndex!].id);

                                              const newWorkingClips = workingClips.map((source, i) => ({
                                                ...source,
                                                ...(i === activeClipIndex && {
                                                  adPods: cloned.sort((a, b) => a.startAt - b.startAt),
                                                }),
                                              }));

                                              setUndo(newWorkingClips);
                                              setWorkingClips(newWorkingClips);
                                            }}
                                          />
                                        </TableActions>
                                      ),
                                    } as ITableCol<ISimpleAdPod>,
                                  ]
                                : []),
                            ]}
                            rows={activeClip.adPods || []}
                            selected={selectedAdPods}
                            onSelect={setSelectedAdPods}
                            predicate='startAt'
                          ></Table>
                        }
                      </Template>
                      <Template label='footer'></Template>
                    </Cover>
                  </Box>
                </Template>
              </Cover>
            </Box>
          ) : (
            <Box width='40.9375rem' widthAtTabletAndDown='50%' widthAtMobile='100%' overflow='auto' fullHeight={true}>
              <Cover center={true}>
                <Template label='cover'>
                  <Stack space='large' alignItems='center'>
                    <Heading level='h3'>There are no clips in this episode yet.</Heading>
                    <Button type='primary' onClick={() => setIsAddClipOpen(true)} permission={editPermission}>
                      + Add Clip
                    </Button>
                  </Stack>
                </Template>
              </Cover>
            </Box>
          )}
        </Cluster>
      </Box>
    );
  },
);

export default EpisodeVideo;
